// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NotificationMobilePage_main__2nXGy {
   padding: 15px 15px;
   background-color: #fff;
   margin: 0 4%;
   border-radius: 5px;
   margin-top: 5%;
}



.NotificationMobilePage_notification__Xbd7A {
    border: 1px solid rgba(106, 101, 253, 1);
    border-radius: 5px;
    padding: 20px;
    margin-top: 5%;
    background-color: #fff;
}
.NotificationMobilePage_notificationIndex0__VY6iu {
    border: 1px solid rgba(106, 101, 253, 1);
    border-radius: 5px;
    padding: 20px;
    background-color: #fff;
}
.NotificationMobilePage_titleNotif__qopkL {
font-size: 16px;
font-weight: 600;
font-family: "Wix Madefor Display", sans-serif;
color: rgba(0, 0, 0, 1);
margin-bottom: 3%;
}

.NotificationMobilePage_descriptionNotif__MrAUw {
    font-family: "Wix Madefor Display", sans-serif;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 3%;
}

.NotificationMobilePage_btnNotif__ShJs7 {
    background: rgba(106, 101, 253, 1);
    color: #fff;
    font-family: "Wix Madefor Display", sans-serif;
    padding: 10px 15px;
    border-radius: 4px;
}`, "",{"version":3,"sources":["webpack://./src/scenes/NotificationMobilePage.module.css"],"names":[],"mappings":"AAAA;GACG,kBAAkB;GAClB,sBAAsB;GACtB,YAAY;GACZ,kBAAkB;GAClB,cAAc;AACjB;;;;AAIA;IACI,wCAAwC;IACxC,kBAAkB;IAClB,aAAa;IACb,cAAc;IACd,sBAAsB;AAC1B;AACA;IACI,wCAAwC;IACxC,kBAAkB;IAClB,aAAa;IACb,sBAAsB;AAC1B;AACA;AACA,eAAe;AACf,gBAAgB;AAChB,8CAA8C;AAC9C,uBAAuB;AACvB,iBAAiB;AACjB;;AAEA;IACI,8CAA8C;IAC9C,eAAe;IACf,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,kCAAkC;IAClC,WAAW;IACX,8CAA8C;IAC9C,kBAAkB;IAClB,kBAAkB;AACtB","sourcesContent":[".main {\n   padding: 15px 15px;\n   background-color: #fff;\n   margin: 0 4%;\n   border-radius: 5px;\n   margin-top: 5%;\n}\n\n\n\n.notification {\n    border: 1px solid rgba(106, 101, 253, 1);\n    border-radius: 5px;\n    padding: 20px;\n    margin-top: 5%;\n    background-color: #fff;\n}\n.notificationIndex0 {\n    border: 1px solid rgba(106, 101, 253, 1);\n    border-radius: 5px;\n    padding: 20px;\n    background-color: #fff;\n}\n.titleNotif {\nfont-size: 16px;\nfont-weight: 600;\nfont-family: \"Wix Madefor Display\", sans-serif;\ncolor: rgba(0, 0, 0, 1);\nmargin-bottom: 3%;\n}\n\n.descriptionNotif {\n    font-family: \"Wix Madefor Display\", sans-serif;\n    font-size: 14px;\n    font-weight: 500;\n    margin-bottom: 3%;\n}\n\n.btnNotif {\n    background: rgba(106, 101, 253, 1);\n    color: #fff;\n    font-family: \"Wix Madefor Display\", sans-serif;\n    padding: 10px 15px;\n    border-radius: 4px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `NotificationMobilePage_main__2nXGy`,
	"notification": `NotificationMobilePage_notification__Xbd7A`,
	"notificationIndex0": `NotificationMobilePage_notificationIndex0__VY6iu`,
	"titleNotif": `NotificationMobilePage_titleNotif__qopkL`,
	"descriptionNotif": `NotificationMobilePage_descriptionNotif__MrAUw`,
	"btnNotif": `NotificationMobilePage_btnNotif__ShJs7`
};
export default ___CSS_LOADER_EXPORT___;
