export const PATHS = {

    other: "/*",
    empty: "/",

    login: "/",
    recoverPassword: "recover",
    newPassword: "newpassword",
    confirmEmail: "confirmemail",
    confirmEmailPswd: "confirmemailpswd",
    signUp: "signup",
    plans: "plans",
    myLearning: "mylearning",
    allCourses: "allcourses",
    timeTable: "timetable",
    support: "support",
    profile: "profile",
    basket: "basket",
    invite: "invite",
    notification: "notification",
    chat: "chat",
    vkauth: "vkauth",
}


