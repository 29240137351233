// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Support_supportContainer__qVzg3 {
    font-family: "Wix Madefor Display", sans-serif;
    margin: auto;
    margin-left: 50%;
  }
  
  .Support_main__BOQdb {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: -10vw;
    margin-top: 20vh;
    width: 100vw;
    max-width: 1900px;
  }
  .Support_supportButton__uQCld {

    font-family: "Wix Madefor Display", sans-serif;
    background-color: rgba(39, 135, 245, 1);
    color: white;
    border: none;
    padding: 25px 35px;
    border-radius: 15px;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
    transition: background-color 0.3s ease;
    box-shadow: 0 4px 20px rgba(31, 196, 255, 0.6); 
    transition: box-shadow 0.3s ease-in-out; 
  }
  
  .Support_supportButton__uQCld:hover {
    background-color: rgba(39, 135, 245, 1);
    box-shadow: 0 0 30px rgba(31, 196, 255, 1), 
    0 0 60px rgba(31, 196, 255, 0.6); 

  }
  
  .Support_vkLogo__fo371 {
    width: 24px;
    height: 24px;
  }

@media (max-width: 768px) {
  .Support_main__BOQdb {
    margin-left: 0;
    width: 70vw;
    margin: auto;
    margin-top: 30vh;
  }

  .Support_supportButton__uQCld {
    font-size: 20px;
    padding: 15px 25px;
  }
}
  
  /* 100% зум */
`, "",{"version":3,"sources":["webpack://./src/scenes/Support.module.css"],"names":[],"mappings":"AAAA;IACI,8CAA8C;IAC9C,YAAY;IACZ,gBAAgB;EAClB;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,kBAAkB;IAClB,gBAAgB;IAChB,YAAY;IACZ,iBAAiB;EACnB;EACA;;IAEE,8CAA8C;IAC9C,uCAAuC;IACvC,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,mBAAmB;IACnB,eAAe;IACf,iBAAiB;IACjB,eAAe;IACf,aAAa;IACb,mBAAmB;IACnB,SAAS;IACT,sCAAsC;IACtC,8CAA8C;IAC9C,uCAAuC;EACzC;;EAEA;IACE,uCAAuC;IACvC;oCACgC;;EAElC;;EAEA;IACE,WAAW;IACX,YAAY;EACd;;AAEF;EACE;IACE,cAAc;IACd,WAAW;IACX,YAAY;IACZ,gBAAgB;EAClB;;EAEA;IACE,eAAe;IACf,kBAAkB;EACpB;AACF;;EAEE,aAAa","sourcesContent":[".supportContainer {\n    font-family: \"Wix Madefor Display\", sans-serif;\n    margin: auto;\n    margin-left: 50%;\n  }\n  \n  .main {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    margin-left: -10vw;\n    margin-top: 20vh;\n    width: 100vw;\n    max-width: 1900px;\n  }\n  .supportButton {\n\n    font-family: \"Wix Madefor Display\", sans-serif;\n    background-color: rgba(39, 135, 245, 1);\n    color: white;\n    border: none;\n    padding: 25px 35px;\n    border-radius: 15px;\n    font-size: 28px;\n    font-weight: bold;\n    cursor: pointer;\n    display: flex;\n    align-items: center;\n    gap: 10px;\n    transition: background-color 0.3s ease;\n    box-shadow: 0 4px 20px rgba(31, 196, 255, 0.6); \n    transition: box-shadow 0.3s ease-in-out; \n  }\n  \n  .supportButton:hover {\n    background-color: rgba(39, 135, 245, 1);\n    box-shadow: 0 0 30px rgba(31, 196, 255, 1), \n    0 0 60px rgba(31, 196, 255, 0.6); \n\n  }\n  \n  .vkLogo {\n    width: 24px;\n    height: 24px;\n  }\n\n@media (max-width: 768px) {\n  .main {\n    margin-left: 0;\n    width: 70vw;\n    margin: auto;\n    margin-top: 30vh;\n  }\n\n  .supportButton {\n    font-size: 20px;\n    padding: 15px 25px;\n  }\n}\n  \n  /* 100% зум */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"supportContainer": `Support_supportContainer__qVzg3`,
	"main": `Support_main__BOQdb`,
	"supportButton": `Support_supportButton__uQCld`,
	"vkLogo": `Support_vkLogo__fo371`
};
export default ___CSS_LOADER_EXPORT___;
