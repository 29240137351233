import NotificationIcon from "../Icons/NotificationsIcon";
import style from "./TopBar.module.css";
import logo from "../../img/Logo.png";
import defAvatar from "../../img/photo_5406613332124882751_m.jpg";
import { useEffect, useState, useRef } from "react";
import Notification from "../../scenes/Notification";
import { useLocation, useNavigate } from "react-router-dom";
import { PATHS } from "../../services/Consts";
import axios from "axios";

import { Context } from "../../App";
import { useContext } from "react";

function TopBar({ mobileTitle }) {
    const [isAvatar, setIsAvatar] = useState(false);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);

    const [avatar, setAvatar] = useState(null);
    
    const [width, setWidth] = useState(window.innerWidth);
    const [notificationField, setNotificationField] = useState(false);
    const [notifications, setNotifications] = useState(null);
    const [unreadCount, setUnreadCount] = useState(0); 
    const notificationRef = useRef(null); 
    const [widthTitle, setWidthTitle] = useState(0);

    const context = useContext(Context);

    const navigate = useNavigate();

    function handleClick() {
        navigate(`../${PATHS.notification}`, { replace: false });
    }

    useEffect(() => {
        fetch('https://extremumcourse.ru/api/v2/account/profile', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            }
        })
            .then(response => {
                if (response.status === 200) {
                    return response.json();
                } else if (response.status === 400) {
                    throw new Error("Unauthorized");
                } else {
                    throw new Error("Неизвестная ошибка");
                }
            })
            .then(data => setData(data))
            .catch(error => setError(error.message));
    }, []);

    useEffect(() => {
        const token = localStorage.getItem('token');

        fetch('https://extremumcourse.ru/api/v2/account/avatar', {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                if (response.status === 200) {
                    setIsAvatar(true);
                    return response.blob();
                } else if (response.status === 404) {
                    setIsAvatar(false);
                    return null;
                } else if (response.status === 400) {
                    throw new Error("Unauthorized");
                } else {
                    throw new Error("Неизвестная ошибка");
                }
            })
            .then(avatarBlob => {
                if (avatarBlob) {
                    context.setAvatar(URL.createObjectURL(avatarBlob));
                }
            })
            .catch(error => {
                if (error.message !== "Не найдено") {
                    setError(error.message);
                }
            });
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
            if (context.topBarTitle) {
                setWidthTitle(context.topBarTitle.length);
            }
        };
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [context.topBarTitle]);
    
    useEffect(() => {
        const fetchNotifications = async () => {
            try {
                const response = await axios.get("https://extremumcourse.ru/api/notifications", {
                    headers: {
                        "Authorization": `Bearer ${localStorage.getItem('token')}`,
                        'Content-Type': 'application/json'
                    }
                });

                setNotifications(response.data);
                const unreadNotifications = response.data.filter(notification => !notification.read);
                setUnreadCount(unreadNotifications.length); 
            } catch (error) {
                console.error("Error fetching notifications:", error);
            }
        };

        fetchNotifications();
    }, []);
    useEffect(() => {

        const handleClickOutside = (event) => {
            if (notificationRef.current && !notificationRef.current.contains(event.target)) {
                setNotificationField(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [notificationField]);

    const handleNotificationClick = () => {
        setNotificationField(!notificationField);
        setUnreadCount(0); 
    };

    if (width > 768) {
        return (
            <div className={style.mainContainer}>
                <div className={style.logo}>
                    <img src={logo} alt="logo" />
                </div>
                <div className={notificationField ? style.notificationsActive : style.notifications} style={{ cursor: "pointer" }} onClick={handleNotificationClick} ref={notificationRef}>
                    <Notification />
                    {unreadCount > 0 && (
                        <div className={style.unreadCount}>
                            {unreadCount}
                        </div>
                    )}
                    {(notificationField && notifications) && (
                        <div className={style.notificationsContainer} >
                            {notifications.length > 0 ? (
                                notifications.map((notification, index) => (
                                    <div key={index} className={style.notification}>
                                        <div className={style.titleNotif}>{notification.title}</div>
                                        <div className={style.descriptionNotif}>{notification.description}</div>
                                        {notification.elements && notification.elements.length > 0 && notification.elements.map((element, i) => (
                                            element.type === 'button' ? (
                                                <button className={style.btnNotif} key={i} onClick={() => window.location.href = element.redirect ? element.redirect.url : '#'}>{element.value}</button>
                                            ) : null
                                        ))}
                                    </div>
                                ))
                            ) : (
                                <div className={style.noNotifications}>
                                    У вас нет уведомлений
                                </div>
                            )}
                        </div>
                    )}
                </div>
                <div className={style.profileContainer} onClick={()=>{navigate(PATHS.profile); context.setMenuItem(4)}}>
                    {error ? (
                        <div className={style.profileName}>Ошибка: {error}</div>
                    ) : data ? (
                        <>
                            <div className={style.profileName}>{data.first_name + " " + data.last_name}</div>
                            <img className={style.profileIcon} src={context.avatar} alt="ава" />
                        </>
                    ) : (
                        null
                    )}
                </div>
            </div>
        );
    } else {
        return (
            <div className={style.mobileTopBar}>
                <p className={widthTitle > 18 ? style.locate2 : style.locate}>{context.topBarTitle}</p>
                <div onClick={() => { handleClick() }} className={style.notifications2} style={{ cursor: "pointer" }}>
                    <Notification />
                    {unreadCount > 0 && (
                        <div className={width >768 ? style.unreadCount : style.unreadCount2}>
                            {unreadCount}
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default TopBar;
