import { useNavigate, useParams, Routes, Route } from "react-router-dom";
import { useState, useEffect } from "react";
import style from "./Lesson.module.css";
import API from "../../services/API";

import docImg from "../../img/doc.png"
import Tasks from "../Tasks/Tasks";
import Loading from "../Load/Loading";

function Lesson() {
    const [IS_VERTICAL, SET_IS_VERTICAL] = useState(window.innerWidth < 932)
    const navigate = useNavigate();

    window.addEventListener('resize', ()=>{
        SET_IS_VERTICAL(window.innerWidth < 932)
    }, false)

    const {LessonId} = useParams();
    const [lesson, setLesson] = useState();
    const [isLoading, setIsLoading] = useState(true);

    const [allTasks, setAllTasks] = useState();
    const [completedTasks, setCompletedTasks] = useState();

    function escBtnHandle() {
        navigate(-1);
    }

    function toTasks() {
        navigate("tasks/1", { relative: "path"});
    }

    useEffect(()=>{
        async function getLesson() {
            let result = await API.GetMyCourseLesson(LessonId); 
            setLesson(result);
            setAllTasks(result.allTasks);
            setCompletedTasks(result.completedTasks);
            setIsLoading(false);
        }
        getLesson();
    }, [])

    if (isLoading) {
        return (
                <Loading/>
        )
    }


    function getTaskForm(x) {
        switch (x % 100) {
            case 11:
            case 12:
            case 13:
            case 14:
                return ' заданий';
        }
    
        switch (x % 10) {
            case 1:
                return ' задание';
            case 2:
            case 3:
            case 4:
                return ' задания';
            default:
                return ' заданий';
        }
    }

    function getDateForm(time) {
        let date = new Date(time);
        date = date.toLocaleDateString('ru-RU', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        })
        return date.toString();
    }

    function LessonDescription() {

        if (window.innerWidth < 932) return (
                <>
            <div className={style.mobileContainer}>
                    <div className={style.top}>
                        <h2>{lesson.title}</h2>
                    </div>
                    <div className={style.videoContainer}>
                        <iframe src={lesson.link}>
                        </iframe>    
                    </div>
                    <div className={style.description}>
                        <p>{lesson.description}</p>
                    </div>
                    <div className={style.downloadMaterials}>
                        Полезные материалы:
                        <div>
                            <div className={style.docLink}>
                                <img src={docImg}></img><a href={""} target="_blank">Лайфхаки на экзамене</a><br/>
                            </div>
                            <div className={style.docLink}>
                                <img src={docImg}></img><a href={""} target="_blank">Быстрая подготовка</a><br/>
                            </div>
                        </div>
                </div>
            </div>
                <div className={style.mobileContainer}>
                <div className={style.homework}>
                <h2 style={{textAlign: "center"}}>
                    Домашнее задание
                </h2>
                <div className={style.homeworkDescription}>
                </div>
                <div className={style.progressStatus}>
                    {
                        lesson.passed ? "Работа сдана" : lesson.completedTasks + "/" + lesson.allTasks + getTaskForm(lesson.completedTasks) + " завершено"
                    }
                </div>
                <div className={style.progress}>
                    <div className={style.progressBar}>
                        <div className={style.completedBar} style={{width: lesson.completedTasks / lesson.allTasks * 100 + "%"}}></div>
                    </div>
                </div>
                <div className={style.deadLine}>Работу нужно сдать до {getDateForm(lesson.deadlineAt)}</div>
                <button onClick={() => toTasks()}>
                    К заданиям
                </button>
            </div>
                </div>
            </>    
        )

        return (
            <div className={style.lesson}>
            <div className={style.escButton} onClick={escBtnHandle}>
                {'< К урокам'}
            </div>
            <div className={style.title}>
                {lesson.title}
            </div>
            <div className={style.clearfix}></div>
            <div className={style.video}>
                <iframe src={lesson.link}>
                </iframe>
            </div>
            <div className={style.description}>
                {lesson.description}
            </div>
            <div className={style.downloadMaterials}>
                <h3>
                    Полезные материалы:
                </h3>
                <div>
                    <div className={style.docLink}>
                        <img src={docImg}></img><a href={""} target="_blank">Лайфхаки на экзамене</a><br/>
                    </div>
                    <div className={style.docLink}>
                        <img src={docImg}></img><a href={""} target="_blank">Быстрая подготовка</a><br/>
                    </div>
                </div>
            </div>
            <div className={style.line}>

            </div>
            <div className={style.homework}>
                <div className={style.homeworkTitle}>
                    Домашнее задание
                </div>
                <div className={style.homeworkDescription}>
                </div>
                <div className={style.progressStatus}>
                    {
                        lesson.passed ? "Работа сдана" : lesson.completedTasks + "/" + lesson.allTasks + getTaskForm(lesson.completedTasks) + " завершено"
                    }
                </div>
                <div className={style.progress}>
                    <div className={style.progressBar}>
                        <div className={style.completedBar} style={{width: lesson.completedTasks / lesson.allTasks * 100 + "%"}}></div>
                    </div>
                </div>
                <div className={style.deadLine}>Работу нужно сдать до {getDateForm(lesson.deadlineAt)}</div>
                <button onClick={() => toTasks()}>
                    К заданиям
                </button>
            </div>
        </div>
        )
    }

    return (
        <Routes>
            <Route path="/" element={<LessonDescription/>}/>
            <Route path="/tasks/:number" element={<Tasks
                                                    allTasks={allTasks}
                                                    setAllTasks={setAllTasks}
                                                    completedTasks={completedTasks}
                                                    setCompletedTasks={setCompletedTasks}
                                                    isPassed={lesson.passed}
                                                />
            }/>
        </Routes>
    )
}

export default Lesson;