import style from './LogForms.module.css';
import { useEffect, useState } from 'react';
import logo from "../../img/Logo.png";
import { useNavigate } from 'react-router-dom';
import { PATHS } from '../../services/Consts';

function SignUp({ onLoginSuccess, onGetEmail, userData, setUserData }) {
    const [successReg, setSuccessReg] = useState(false);
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [isLogin, setIsLogin] = useState(false);
    const [isSignUp, setIsSignUp] = useState(true);
    const [registerLogin, setRegisterLogin] = useState('');
    const [registerPassword, setRegisterPassword] = useState('');
    const [registerRepeatPassword, setRegisterRepeatPassword] = useState('');
    const [isEmptyField, setIsEmptyField] = useState(false);
    const [isRepeatPassword, setIsRepeatPassword] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const loginButtonStyle = { color: isLogin ? 'black' : 'rgba(0, 0, 0, 0.5)' };
    const registerButtonStyle = { color: isSignUp ? 'black' : 'rgba(0, 0, 0, 0.5)' };
    const validateMessage = { opacity: isEmptyField || isRepeatPassword || errorMessage ? '1' : '0' };

    const navigate = useNavigate();
    useEffect(() => {
        alert("🆘 Подсказка для создания корректного пароля:\n❗ хотя бы 1 заглавная; \n❗ хотя бы 1 строчная; \n❗ хотя бы одно число; \n❗ длина не меньше 8 символов.")
    },[])
    
    const handleRecoverPassword = () => {
        navigate(PATHS.recoverPassword, { replace: false });
    };

    function handleChangeLogin() {
        setIsSignUp(true);
        setIsLogin(false);
        setIsEmptyField(false);
        setIsRepeatPassword(false);
        setLogin('');
        setPassword('');
        setErrorMessage('');
    }

    function handleChangeSignUp() {
        setIsLogin(true);
        setIsSignUp(false);
        setIsEmptyField(false);
        setIsRepeatPassword(false);
        setRegisterLogin('');
        setRegisterPassword('');
        setRegisterRepeatPassword('');
        setErrorMessage('');
        navigate("../", { replace: false });
    }

    function handleSubmitLogin(e) {
        e.preventDefault();
        if (login.length > 0 && password.length > 0) {
            fetch('https://extremumcourse.ru//api/v2/account/auth/email/login', { //new
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: login,
                    password: password,
                }),
            })
            .then(response => {
                if (response.status === 200) {
                    return response.json();
                } else if (response.status === 400) {
                    throw new Error('Неправильный запрос.');
                } else if (response.status === 406) {
                    throw new Error('Требуется подтверждение аккаунта.');
                } else {
                    throw new Error('Неизвестная ошибка.');
                }
            })
            .then(data => {

                onLoginSuccess(data.token); 
                setSuccessReg(true);
                navigate(PATHS.empty, { replace: false});
            })
            .catch(error => {
                setErrorMessage(error.message);
                console.error('Error:', error);
            });
        } else {
            setIsEmptyField(true);
        }
    }

    function handleSubmitRegister(e) {
        e.preventDefault();
        setErrorMessage(''); 
        setIsEmptyField(false);
        setIsRepeatPassword(false);
    
        if (registerLogin.length === 0 || registerPassword.length === 0 || registerRepeatPassword.length === 0) {
            setIsEmptyField(true);
            setErrorMessage('Все поля должны быть заполнены.');
            return;
        }
    
        if (registerPassword !== registerRepeatPassword) {
            setIsRepeatPassword(true);
            setErrorMessage('Пароли не совпадают.');
            return;
        }

        onGetEmail(registerLogin);
        userData.email = registerLogin;
        userData.password = registerPassword;

        
        fetch('https://extremumcourse.ru/api/v2/account/auth/email/register', { //new
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                first_name: userData.firstName,
                last_name: userData.lastName,
                email: userData.email,
                password: userData.password,

            }),
        })
        .then(response => response.json().then(data => ({ status: response.status, body: data })))

        .then(({ status, body }) => {
            if (status === 200) {
                setUserData(userData)
                localStorage.setItem('token', body.verification_token);
                setSuccessReg(true);
                navigate("../" + PATHS.confirmEmail);
                setRegisterLogin('');
                setRegisterPassword('');
                setRegisterRepeatPassword('');
                
            } else {
                let errorMessage = "Неизвестная ошибка.";
                if (status === 400) {
                    errorMessage = "Некорректные данные.";
                } else if (status === 409) {
                    errorMessage = "Пользователь с такой почтой уже существует.";
                }
                throw new Error(errorMessage);
            }
        })
    }

    if (isLogin) {
        return (
            <div className={style.main}>
                <div className={style.logo}>
                    <img src={logo} alt="logo" />
                </div>
                <div className={style.container}>
                    <div className={style.navContainer}>
                        <div className={style.navLogContainer}>
                            <button style={loginButtonStyle} className={style.login} onClick={handleChangeSignUp}>Вход</button>
                        </div>
                        <div className={style.navRegContainer}>
                            <button style={registerButtonStyle} className={style.signin} onClick={handleChangeLogin}>Регистрация</button>
                        </div>
                    </div>
                    <div className={style.formContainer}>
                        <form className={style.form} onSubmit={handleSubmitLogin}>
                            <div className={style.loginInputContainer}>
                                <label className={style.loginLabel}>Телефон или почта</label>
                                <input className={style.loginInput} 
                                    type="text"
                                    onChange={e => setLogin(e.target.value)}
                                    value={login}
                                />
                            </div>
                            <div className={style.passwordInputContainer}>
                                <label className={style.passwordLabel}>Пароль</label>
                                <input className={style.passwordInput}
                                    type="password"
                                    value={password}
                                    onChange={e => setPassword(e.target.value)}/>
                            </div>
                            <div className={style.validateMessage} style={validateMessage}>{errorMessage || (isEmptyField && "Ошибка, проверьте введенные данные!")}</div>
                            <button className={style.btnLogin} type="submit">Далее</button>
                        </form>
                        <button onClick={handleRecoverPassword} className={style.recoverPassword}>Восстановить пароль</button>
                    </div>
                </div>
            </div>
        );
    } else if (isSignUp) {
        return (
            <div className={style.main}>
                <div className={style.logo}>
                    <img src={logo} alt="logo" />
                </div>
                <div className={style.container}>
                    <div className={style.navContainer}>
                        <button style={loginButtonStyle} className={style.login} onClick={handleChangeSignUp}>Вход</button>
                        <button style={registerButtonStyle} className={style.signin} onClick={handleChangeLogin}>Регистрация</button>
                    </div>
                    <div className={style.formContainer}>
                        <form className={style.form} onSubmit={handleSubmitRegister}>
                            <div className={style.loginInputContainer}>
                                <label className={style.loginLabel}>Почта</label>
                                <input className={style.loginInput} 
                                    type="text"
                                    onChange={e => setRegisterLogin(e.target.value)}
                                    value={registerLogin}
                                />
                            </div>
                            <div className={style.passwordInputContainer}>
                                <label className={style.passwordLabel}>Пароль</label>
                                <input className={style.passwordInput} 
                                    type="password"
                                    onChange={e => setRegisterPassword(e.target.value)}
                                    value={registerPassword}
                                />
                            </div>
                            <div className={style.passwordInputContainer}>
                                <label className={style.passwordLabel}>Повторите пароль</label>
                                <input className={style.passwordInput} 
                                    type="password"
                                    onChange={e => setRegisterRepeatPassword(e.target.value)}
                                    value={registerRepeatPassword}
                                />
                            </div>
                            <div className={style.validateMessage} style={validateMessage}>{errorMessage}</div>
                            <button className={style.btnLogin2} type="submit">Далее</button>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default SignUp;
