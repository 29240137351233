function OpenBookIcon(props) {
    return (
        <div width="100%" height="100%">
            <svg width="32" height="40" viewBox="0 0 32 40" fill="none" xmlns="https://www.w3.org/2000/svg">
                <path
                    d="M15.9998 18.7031C13.8626 17.1231 10.6598 14.3602 1.47694 14.3602V34.1431C10.6598 34.1431 13.8626 36.9059 15.9998 38.4859M15.9998 18.7031V38.4859M15.9998 18.7031C18.1369 17.1231 21.3398 14.3602 30.5227 14.3602V34.1431C21.3398 34.1431 18.1369 36.9059 15.9998 38.4859M21.6741 1.64306V8.59163M26.7512 1.48877H7.67408C7.14109 1.48812 6.61855 1.63657 6.16551 1.91734L1.14551 5.04306L6.16551 8.16306C6.61855 8.44383 7.14109 8.59228 7.67408 8.59163H26.7512C27.2259 8.6051 27.6984 8.52324 28.1409 8.35089C28.5834 8.17854 28.9868 7.91921 29.3273 7.58823C29.6678 7.25725 29.9385 6.86135 30.1233 6.42393C30.3081 5.98652 30.4034 5.51649 30.4034 5.04163C30.4034 4.56677 30.3081 4.09674 30.1233 3.65932C29.9385 3.22191 29.6678 2.82601 29.3273 2.49503C28.9868 2.16405 28.5834 1.90472 28.1409 1.73237C27.6984 1.56002 27.2259 1.4753 26.7512 1.48877Z"
                    fill="none"
                    strokeWidth="2px"
                    stroke={props.mainColor}
                />
            </svg>

        </div>
    )
}

export default OpenBookIcon;