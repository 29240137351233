class API {

    static API_URL = "https://extremumcourse.ru/api/";
    
    static async Ping() {   
        
    }

    static async GetMyCurses() {
        let result = await fetch(this.API_URL + "my-edu/courses", {
            method: "GET",
            mode: "cors",
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("token"),
                "Content-Type": "application/json",
            }
        });

        

        if (result.ok) {
            return await result.json();
        } else {
            console.log("Failed to fetch my courses");
            return null;
        }
    }

    static async GetMyCourse(CourseId) {
        let result = await fetch(this.API_URL + "my-edu/course/" + CourseId, {
            method: "GET",
            mode: "cors",
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("token"),
                "Content-Type": "application/json",
            }
        });

        if (result.ok) {
            return await result.json();
        } else {
            console.log("Failed to fetch my course");
            return null;
        }
    }

    static async GetMyCourseImage(CourseId) {
        let result = await fetch(this.API_URL + "my-edu/course/" + CourseId + "/image", {
            method: "GET",
            mode: "cors",
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("token"),
                "Content-Type": "application/json",
            }
        });

        if (result.ok) {
            return await result.blob();
        } else {
            console.log("Failed to get course " + CourseId + "image");
            return null;
        }
    }

    static async GetMyCourseLessons(CourseId, limit = 5, offset = 0) {
        let result = await fetch(this.API_URL + "my-edu/course/" + CourseId + "/lessons?limit=" + limit + "&offset=" + offset, {
            method: "GET",
            mode: "cors",
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("token"),
                "Content-Type": "application/json",
            },
        });

        if (result.ok) {
            return await result.json();
        } else {
            console.log("Failed to get my course's lessons");
            return null;
        }
    }

    static async GetMyCourseLesson(lessonId) {
        let result = await fetch(this.API_URL + "my-edu/lesson/" + lessonId, {
            method: "GET",
            mode: "cors",
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("token"),
                "Content-Type": "application/json",
            },
        });

        if (result.ok) {
            return await result.json();
        } else {
            console.log("Failed to get my lesson " + lessonId);
            return null;
        }
    }

    static async GetLessonFile(lessonId, filename) {
        let result = await fetch(this.API + "my-edu/lesson/" + lessonId + "/file/" + filename, {
            method: "GET",
            mode: "cors",
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("token"),
                "Content-Type": "application/json",
            }
        });

        if (result.ok) {
            return await result.blob();
        } else {
            console.error("Не удалось получить файл: " + filename)
            return null;
        }
    }

    

    static async GetLessonTasks(lessonId) {
        let result = await fetch(this.API_URL + "my-edu/lesson/" + lessonId + "/tasks", {
            method: "GET",
            mode: "cors",
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("token"),
                "Content-Type": "application/json",
            },
        });

        if (result.ok) {
            return await result.json();
        } else {
            console.log("Failed to get my lesson's tasks " + lessonId);
            return null;
        }
    }

    static async GetLessonDeadline(lessonId) {
        let result = await fetch(this.API_URL + "notifications/make-in-time", {
            method: "GET",
            mode: "cors",
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("token"),
                "Content-Type": "application/json",
            },
        });

        if (result.ok) {
            return await result.json();
        } else {
            return null;
        }
    }

    static async ChangeLessonState(lessonId, completed = false, passed = false) {
        let result = await fetch(this.API_URL + "my-edu/lesson/" + lessonId, {
            method: "PATCH",
            mode: "cors",
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("token"),
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                completed: completed,
                passed: passed,
            })
        });

        if (result.ok) {
            return await result.json();
        } else {
            return null;
        }
    }



    static async SaveTaskAnswer(lessonId, taskId, answer) {
        let result = await fetch(this.API_URL + "my-edu/lesson/" + lessonId + "/task/" + taskId + "/answer/input", {
            method: "PUT",
            mode: 'cors',
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("token"),
                "Content-Type": "application/json",                
            },
            body: JSON.stringify({answer: answer}),           
        });

        if (result.ok) {
            return result;
        } else {
            return null;
        }
    }

    static async SaveTaskAnswerFile(lessonId, taskId, file, filename) {
        let data = new FormData();
        data.append('file', file);
        let result = await fetch(this.API_URL + "my-edu/" + lessonId + "/task/" + taskId + "/answer/files/" + filename, {
            method: "POST",
            mode: "cors",
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("token"),
                "Content-Type": "application/json",
            },
            body: data,
        });

        if (result.ok) {
            return result;
        } else {
            return null;
        }
    }

    static async GetTaskAnswerFile(lessonId, taskId, filename) {
        let result = await fetch(this.API_URL + "my-edu/" + lessonId + "/task/" + taskId + "/answer/files/" + filename, {
            method: "GET",
            mode: "cors",
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("token"),
                "Content-Type": "application/json",
            }
        });

        if (result.ok) {
            return await result.blob();
        } else {
            return null;
        }
    }

    static async DeleteTaskAnswerFile(lessonId, taskId, filename) {
        let result = await fetch(this.API_URL + "my-edu/" + lessonId + "/task/" + taskId + "/answer/files/" + filename, {
            method: "DELETE",
            mode: "cors",
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("token"),
                "Content-Type": "application/json",
            },
        });

        if (result.ok) {
            return result;
        } else {
            return null;
        }
    }


    static async changePassword(lastPassword, newPassword) {
        const result = await fetch(this.API_URL + "v2/account/auth/email/change-password", {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("token"),
            },
            body: JSON.stringify({
                old_password: lastPassword,
                new_password: newPassword
            }),
        });

        if (result.ok) {
            return result;
        } else {
            return null;
        }
    }

    static async getProfile() {
        const result = await fetch(this.API_URL + "v2/account/profile", {
            method: "GET",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("token"),
            }
        });

        if (result.ok) {
            return await result.json();
        } else {
            return null;
        }
    }

    static async patchProfile(newProfile) {
        const result = await fetch(this.API_URL + "v2/account/profile", {
            method: "PUT",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("token"),
            },
            body: JSON.stringify(newProfile),
        });

        if (result.ok) {
            return await result.json();
        } else {
            return null;
        }
    }

    static async getAvatar() {
        const result = await fetch(this.API_URL + "v2/account/avatar", {
            method: "GET",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("token"),
            },
        });


        if (result.ok) {
            return await result.blob();
        } else {
            return null;
        }
    }

    static async changeAvatar(image) {
        var data = new FormData();
        data.append('image', image);
        const result = await fetch(this.API_URL + "v2/account/avatar", {
            method: "POST",
            mode: "cors",
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("token"),
            },
            body: data,
        });

        return result;
    }

    static async deleteAvatar() {
        const result = await fetch(this.API_URL + "v2/account/avatar", {
            method: "DELETE",
            mode: "cors",
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("token"),
            },
        });

        if (result.ok) {
            return result.ok;
        } else {
            return null;
        }
    } 

    static async bindTelegram() {
        const result = await fetch(this.API_URL + "v2/account/auth/telegram/bind", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("token"),
            }
        });

        if (result.ok) {
            return await result.json();
        } else {
            return null;
        }
    }
}

export default API;