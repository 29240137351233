// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PlayGround_svgIcon__pQRXK {
    fill: none;
    width: 100px;
    height: 100px;
    
    transform: scale(1, 1) translate(50%, 50%);
    
    path {
        fill: "none";
        stroke: red;
        stroke-width: 3;
        stroke-linecap: round;
        stroke-linejoin: round;
    }
}

`, "",{"version":3,"sources":["webpack://./src/scenes/PlayGround.module.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,YAAY;IACZ,aAAa;;IAEb,0CAA0C;;IAE1C;QACI,YAAY;QACZ,WAAW;QACX,eAAe;QACf,qBAAqB;QACrB,sBAAsB;IAC1B;AACJ","sourcesContent":[".svgIcon {\n    fill: none;\n    width: 100px;\n    height: 100px;\n    \n    transform: scale(1, 1) translate(50%, 50%);\n    \n    path {\n        fill: \"none\";\n        stroke: red;\n        stroke-width: 3;\n        stroke-linecap: round;\n        stroke-linejoin: round;\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"svgIcon": `PlayGround_svgIcon__pQRXK`
};
export default ___CSS_LOADER_EXPORT___;
