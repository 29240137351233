import { useEffect, useRef, useState} from "react"
import { useParams, useNavigate  } from "react-router-dom";
import { InlineMath, BlockMath } from "react-katex";

import style from "./Tasks.module.css";
import API from "../../services/API";
import { IoMdClose } from "react-icons/io";
import Loading from "../Load/Loading"
import { Pagination } from "@mui/material";
import Chat from "../Chat/Chat";
import { PATHS }from "../../services/Consts"
import 'katex/dist/katex.min.css'
import Mark from "../Mark/Mark";

function Tasks(props) {
    const [IS_VERTICAL, SET_IS_VERTICAL] = useState(window.innerWidth < 932)

    window.addEventListener('resize', (event)=>{
        SET_IS_VERTICAL(window.innerWidth < 768)
    }, false)

    let {
        allTasks,
        setAllTasks,
        completedTasks,
        setCompletedTasks,
        isPassed,
    } = props;

    const {LessonId} = useParams();
    const [lessonTitle, setLessonTitle] = useState();
    const [lesson, setLesson] = useState();
    const [currTask, setCurrTask] = useState(0);
    const [tasks, setTasks] = useState(null);
    const [answers, setAnswers] = useState({});

    const [isLoading, setIsLoading] = useState(true);
    const [isChat, setIsChat] = useState(false);

    const [answer, setAnswer] = useState("");   
    const [sendingAnswer, setSendingAnswer] = useState(false);
    const [sendingHomework, setSendingHomework] = useState(false);
    const [isOpen, setIsOpen] = useState(false)
    const [isMarkOpen, setIsMarkOpen] = useState(false)
    const [textArea, setTextArea] = useState(null)
    
    const [visNumLimit, setVisNumLimit] = useState(14);

    const {courseId} = useParams("CourseId")
    const params = useParams()



    const navigate = useNavigate();

    function escButtonHandler() {
        navigate(-1)
    }

    function formatKaTeXString(str) {
        const maxLength = 20;
        let formattedStr = '';
      
        for (let i = 0; i < str.length; i += maxLength) {
          // Slice the string into chunks of maxLength
          formattedStr += str.slice(i, i + maxLength);
          // Add line break unless it's the last chunk
          if (i + maxLength < str.length) {
            formattedStr += ' \\\\ ';
          }
        }
      
        return formattedStr;
      }

    function handleChTask(number) {
        setCurrTask(number);
        tasks[number].answer ? setAnswer(tasks[number].answer) : setAnswer("");
    }

    function handleAnswerInput(event) {
        setAnswer(event.target.value);
    }

    async function sendAnswer() {
        setSendingAnswer(true);
        let result = await API.SaveTaskAnswer(LessonId, tasks[currTask].taskId, answer);
        if (tasks[currTask].answer === null) setCompletedTasks(completedTasks + 1);
        tasks[currTask].answer = answer;
        setSendingAnswer(false);
    }

    async function sendHomework() {
        setSendingHomework(true);
        let result = await API.ChangeLessonState(LessonId, true, true);
        setSendingHomework(false);
    }

    function Paging () {
        const numberContainerRef = useRef(null);
        const currentPageRef = useRef(null);

        const [minInd, setMinInd] = useState(0);
        const [maxInd, setMaxInd] = useState(visNumLimit - 1);
        const [numbers, setNumbers] = useState([]);

        function numberClick(element) {
            handleChTask(element);
        }

        function leftArrowClick() {
            setCurrTask(Math.max(currTask - 1, 0))
            setAnswer(tasks[Math.max(currTask - 1, 0)].answer)
            if (currTask - 1 < minInd) setMinInd(Math.max(minInd - 1, 0))
        }

        function rightArrowClick() {
            setCurrTask(Math.min(currTask + 1, tasks.length - 1))
            setAnswer(tasks[Math.min(currTask + 1, tasks.length - 1)].answer)
            if (currTask >= tasks.length ) {
                setMaxInd(maxInd + 1);
                setMinInd(minInd + 1)
            }
        }

        useEffect(()=>{
            if (currentPageRef.current) {
                currentPageRef.current.scrollIntoView({
                    behavier: "smooth",
                    block: "center",
                    inline: "center",
                });
            }

            const container = numberContainerRef.current;
            const innerElements = container.querySelectorAll(style.pageNumber + " " + style.lightBlue);
            innerElements.forEach(element => {
        
            const containerRect = container.getBoundingClientRect();
            const elRect = element.getBoundingClientRect();

            if (elRect.left < containerRect.left || elRect.right > containerRect.right) {
                element.style.display = 'none';
            } else {
                element.style.display = 'inline-block';
            }
            });
        }, [tasks, currTask])
        return (
            <>
            <div className={style.pagination}>
                <div className={style.arrow} onClick={leftArrowClick}>
                <svg width="18" height="18">
                    <use xlinkHref="#left"/>
                </svg>
                </div>
                <div className={style.numberContainer} ref={numberContainerRef}>
                {
                    tasks.map((element, index)=>{
                        return (
                            <div
                            key={index}
                            ref={index === currTask ? currentPageRef : null}
                            className={style.pageNumber + " " +  (index === currTask ? style.blue : style.lightBlue)}
                            onClick={() => handleChTask(index)}
                            >
                                {index + 1}
                            </div>
                        )
                    })
                }
                </div>
                <div className={style.arrow} onClick={rightArrowClick}>
                <svg width="18" height="18">
                    <use xlinkHref="#right" />
                </svg>
                </div>
            </div>

            <svg className={style.hide}>
            <symbol id="left" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"></path></symbol>
            <symbol id="right" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></symbol>
        </svg>
                </>
        )
    }

    function MobileTasks() {
        return (
            <>
            <div className={style.mainContainer}>
                <div className={style.top}>
                    <div style={{
                        textAlign: "left",
                        width: "70%"
                    }}>{lesson.title}</div>
                    <div className={style.answersCount}>{completedTasks + "/" + allTasks} ответов</div>
                </div>
                <Paging/>
                <div className={style.description}>
                    <h3>Задача №{currTask + 1}:</h3>
                    {<BlockMath options={{ displayMode: true }} math={tasks[currTask].description}/> }
                </div>
    
                <div className={style.answerContainer}>
                    <h3>Ваш ответ: </h3>
                    {
                        !isPassed
                            ? <>
                                <input className={style.answerInput} value={answer}></input>
                                <button onClick={sendAnswer}>Подтвердить</button>
                                <button onClick={()=>{navigate(`../../../${PATHS.chat}`)}}>Задать вопрос куратору</button>
                                <div className={style.explanation}>
                                    Пояснение откроется после сдачи полной работы
                                </div>    
                            </>
                            : <>
                                <input className={style.answer} value={answer} readOnly></input>
                                {
                                    tasks[currTask].answerDescription
                                    ? <>
                                        <div className={style.dscrTitle}>
                                            Пояснение:
                                        </div>
                                        <div className={style.explanation}>
                                            {tasks[currTask].answerDescription}
                                        </div>
                                    </>
                                    : <></>
                                }
                            </>
                    }     
                    </div>
            </div>
    
            {
                isPassed
                ? <div>
                    
                </div>
                : <div className={style.mainContainer} style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "1rem",
                    gap: "1rem"
                }}>
                    <button className={style.sendBtn} onClick={sendHomework}>Отправить работу</button>
                    <h4>Можно отправить работу после половины подтвержденных ответов</h4>
                </div>
            }
            </>
        )
    }

    function getAnswerForm(x) {
        switch (x % 100) {
            case 11:
            case 12:
            case 13:
            case 14:
                return ' ответов';
        }
    
        switch (x % 10) {
            case 1:
                return ' ответ';
            case 2:
            case 3:
            case 4:
                return ' ответа';
            default:
                return ' ответов';
        }
    }

    useEffect(()=>{
        async function getTasks() {
            let result = await API.GetLessonTasks(LessonId);
            let lesson = await API.GetMyCourseLesson(LessonId);
            setAnswer(result.tasks[currTask].answer)
            setLesson(lesson)
            setLessonTitle(lesson.title)
            setTasks(result.tasks);
            
            setIsLoading(false);
        }
        getTasks()
    }, []);

    if (isLoading) {
        return (
            <Loading/>
        )
        
    }

    if (window.innerWidth <= 930) {
        return (
            <MobileTasks/>
        )
    }
    
    return (
        <>
        <div className={style.main}>
            <div className={style.top}>
                <div className={style.escButton} onClick={escButtonHandler}>
                    {" < К уроку "}
                </div>
                <div className={style.Title}>
                    {lessonTitle}
                </div>
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "10%",
                    alignItems: "center"

                }}>
                {   
                    !isPassed && allTasks / completedTasks <= 2
                    ? <button className={style.sendButton} onClick={sendHomework} style={{
                        
                    }}>
                        Отправить работу
                    </button>
                    : <button className={style.disableSendButton} style={{
                        alignSelf: "flex-start"
                    }}>
                        Отправить работу
                    </button> 
                }  
                <div className={style.ansCount}>
                    {completedTasks + "/" + allTasks + getAnswerForm(completedTasks)}
                </div>
                </div>
            </div>
            <Paging/>
            <div className={style.dscrTitle}>Задача №{currTask + 1}:</div>
            <div className={style.description}>
            {<BlockMath options={{ displayMode: true }} math={tasks[currTask].description}/> }
            </div>
            <div className={style.dscrTitle}>Ваш ответ:</div>
            {
                !isPassed
                ? <div className={style.answer}>
                    <div className={style.ansCont}>
                        <div style={{
                            position: "relative"
                        }}>
                            <input className={style.answerInput} type="text" placeholder="Ответ" value={answer} onChange={handleAnswerInput}></input>
                            <span className={style.answerBtn} onClick={sendAnswer}>
                                    {sendingAnswer ? "Отправка" : "Подтвердить"}
                            </span>
                        </div>
                    </div>
                    <div className={style.ascBtn} onClick={()=>setIsOpen(true)}>Задать вопрос куратору</div>

                    <div>
            {isOpen && (
    <div className={style.modal2} onClick={() => setIsOpen(false)}>
        <div className={style.modalContent2} onClick={(e) => e.stopPropagation()}>
                <Chat/>
        </div>
        <div className={style.close2} onClick={() => {setIsOpen(false); setIsMarkOpen(true)}}><IoMdClose /></div>
    </div>
)}          </div>

            <div>
            {isMarkOpen && (
                <div className={style.modal3} onClick={() => setIsOpen(false)}>
                <div className={style.modalContent3} onClick={(e) => e.stopPropagation()}>
                        <Mark setTextArea={setTextArea}/> 
                </div>
                <div className={textArea ? style.close3 : style.close32} onClick={() => {setIsMarkOpen(false)}}><IoMdClose /></div>
            </div>
            )}
            </div>




                </div>
                :
                <div className={style.answer}>
                    <input className={style.answerInput} value={answer} readOnly></input>
                </div>
                
            }
            {
                tasks[currTask].answerDescription ?
                <>
                    <div className={style.dscrTitle}>
                        Пояснение:
                    </div>
                    <div className={style.explanation}>
                        {tasks[currTask].answerDescription}
                    </div>
                </>
                : 
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center"
                }}>
                    <div className={style.dscrTitle} style={{
                        margin: "0",
                    }}>
                        Пояснение откроется после сдачи полной работы 
                    </div>
                </div>
            }
        </div>
        </>
    )
}

export default Tasks;