import style from "./Mark.module.css";
import { useState } from "react";
import { FaRegStar } from "react-icons/fa";
import { FaStar } from "react-icons/fa";

export default function Mark({setTextArea}) {
  const [rating, setRating] = useState(0);
  const [showTextArea, setShowTextArea] = useState(false);

  const handleRating = (index) => {
    setRating(index);
    setShowTextArea(index < 4); 
    setTextArea(index < 4);
  };

  return (
    <div className={style.main}>
      <div className={style.title}>Оцените взаимодействие с куратором</div>
      <div className={style.markContainer}>
        {[1, 2, 3, 4, 5].map((index) => (
          <div
            key={index}
            onClick={() => handleRating(index)}
            style={{ cursor: "pointer", fontSize: "30px", margin: "0 5px" }}
          >
            {index <= rating ? <FaStar color="#ffe100" /> : <FaRegStar color="lightgray" />}
          </div>
        ))}
      </div>
      {showTextArea && (
        <textarea
          className={style.textArea}
          placeholder="Опишите, что вам не понравилось в общении с куратором"
        />
      )}
      <button className={style.submitButton}>Отправить</button>
    </div>
  );
}
