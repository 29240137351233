import React, { useState, useEffect, useRef } from 'react';
import styles from './TimeTable.module.css';
import axios from 'axios';
import TimeTableMobile from "./TimeTableMobile";
import { useNavigate, useOutletContext } from 'react-router-dom';
import { Context } from '../App';
import { useContext } from 'react';
import { PATHS } from '../services/Consts';

const months = ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"];

const getDayClass = (day) => {
  let backgroundClass;

  if ((day >= 1 && day <= 5) || (day >= 8 && day <= 12) || (day >= 15 && day <= 19) || (day >= 22 && day <= 26)) {
    backgroundClass = styles['gradient-background'];
  } else if ([6, 7, 13, 14, 20, 21, 27, 28].includes(day)) {
    backgroundClass = styles['light-background'];
  } else {
    backgroundClass = styles['dark-background'];
  }

  if (day === 28) {
    return `${backgroundClass} ${styles['rounded-bottom-right']}`;
  }
  return backgroundClass;
};

const formatDateTime = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

// Определение сдвига для первого дня месяца
const getFirstDayOffset = (year, month) => {
  const firstDay = new Date(year, month, 1).getDay();
  return firstDay === 0 ? 6 : firstDay - 1; // 0 (воскресенье) станет 6, понедельник — 0
};

const TimeTable = () => {
  const context = useContext(Context);
  const [activeMonthIndex, setActiveMonthIndex] = useState(9); 
  const [startMonthIndex, setStartMonthIndex] = useState(6); 
  const [lessons, setLessons] = useState([]);
  const [selectedLesson, setSelectedLesson] = useState(null);
  const [modalPosition, setModalPosition] = useState({ x: 0, y: 0 });
  const [modalType, setModalType] = useState(null); 
  const [width, setWidth] = useState(window.innerWidth);
  const navigate = useNavigate();

  const modalRef = useRef(null);
  useEffect(()=>{
    context.setTopBarTitle("Расписание");
  }, []);

  useEffect(() => {
    const fetchLessons = async () => {
      const startDate = new Date(2024, activeMonthIndex, 1, 0, 0, 0); 
      const endDate = new Date(2024, activeMonthIndex + 1, 0, 23, 59, 59); 

      const startAt = formatDateTime(startDate);
      const endAt = formatDateTime(endDate);
      try {
        const response = await axios.get('https://extremumcourse.ru/api/notifications/make-in-time', {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
          params: { startAt, endAt }
        });
        setLessons(response.data); 
      } catch (error) {
        console.error('Ошибка при получении данных:', error);
      }
    };
    fetchLessons();
  }, [activeMonthIndex]);


  const handleLessonClick = (lesson, event, type) => {
    const { clientX, clientY } = event;
    setSelectedLesson(lesson);
    setModalType(type);
    setModalPosition({ x: clientX, y: clientY });
  };

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setSelectedLesson(null);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Рендер дней с учетом корректного начала недели
  const renderDays = () => {
    const year = 2024 // год, который используется
    const daysInMonth = new Date(year, activeMonthIndex + 1, 0).getDate();
    const firstDayOffset = getFirstDayOffset(year, activeMonthIndex); // Определяем смещение первого дня недели
    const lastDayOffset = 6 - new Date(year, activeMonthIndex, daysInMonth).getDay(); // Смещение для последних пустых дней
    const dayElements = [];
  
    // Добавляем пустые ячейки до первого дня месяца
    for (let i = 0; i < firstDayOffset; i++) {
      dayElements.push(<div key={`empty-${i}`} className={`${styles.day} ${styles['empty-day']}`}></div>);
    }
  
    // Отображаем дни месяца
    for (let i = 1; i <= daysInMonth; i++) {
      const lesson = lessons.find(lesson => new Date(lesson.lessonStartAt).getDate() === i && new Date(lesson.lessonStartAt).getMonth() === activeMonthIndex);
      const deadline = lessons.find(lesson => new Date(lesson.lessonDeadlineAt).getDate() === i && new Date(lesson.lessonDeadlineAt).getMonth() === activeMonthIndex);
  
      const dayClass = i % 7 === 6 || i % 7 === 0 ? styles.weekend : styles.weekday; // Выходные или будни
  
      dayElements.push(
        <div key={i} className={`${styles.day} ${dayClass}`}>
          <div className={styles.date}>{i}</div>
          {lesson && lesson.lessonName && (
            <div className={styles.tag} onClick={(e) => handleLessonClick(lesson, e, 'lesson')}>Урок</div>
          )}
          {deadline && deadline.lessonDeadlineAt && (
            <div className={styles.tag} onClick={(e) => handleLessonClick(deadline, e, 'deadline')}>Д/З</div>
          )}
        </div>
      );
    }
  
    // Добавляем пустые ячейки после последнего дня месяца
    for (let i = 0; i <= lastDayOffset; i++) {
      dayElements.push(<div key={`empty-end-${i}`} className={`${styles.day} ${styles['empty-day']}`}></div>);
    }
  
    return dayElements;
  };
  const handleMonthChange = (direction) => {
    if (direction === 'prev' && startMonthIndex > 0) {
      setStartMonthIndex(startMonthIndex - 1);
    }
    if (direction === 'next' && startMonthIndex < months.length - 6) {
      setStartMonthIndex(startMonthIndex + 1);
    }
  };

  if (width > 768) {
    return (
      <div className={styles.container}>
        <div className={styles.slider}>
          <div className={styles.arrow} onClick={() => handleMonthChange('prev')}>&lt;</div>
          <div className={styles.months}>
            {months.slice(startMonthIndex, startMonthIndex + 6).map((month, index) => (
              <div
                key={month}
                className={`${styles.month} ${activeMonthIndex === startMonthIndex + index ? styles.active : ''}`}
                onClick={() => setActiveMonthIndex(startMonthIndex + index)}
              >
                {month}
              </div>
            ))}
          </div>
          <div className={styles.arrow} onClick={() => handleMonthChange('next')}>&gt;</div>
        </div>
        <div className={styles.calendar}>
          {renderDays()}
        </div>

        {selectedLesson && (
          <div
            ref={modalRef}
            className={styles.modal}
            style={{ top: `${modalPosition.y}px`, left: `${modalPosition.x}px` }}
          >
            <div className={styles.modalContent} style={{cursor: "pointer"}} onClick={() => {navigate("../")}}>
              <div className={styles.left}>
                <div className={styles.title}>{selectedLesson.lessonName}</div>
                <div className={styles.description}>{selectedLesson.courseName}</div>
              </div>
              {modalType === 'lesson' ? (
                    <div className={styles.right}>{new Date(selectedLesson.lessonStartAt).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</div>
                      ) : (
                    <div className={styles.right}>{new Date(selectedLesson.lessonDeadlineAt).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</div>
                    )}

            </div>
          </div>
        )}
      </div>
    );
  } else {
    return (
      <>
        <TimeTableMobile />
      </>
    );
  }
};

export default TimeTable;
