import { BrowserRouter, Routes, Route, Outlet, useOutletContext, useLocation } from "react-router-dom";
import CoursesList from "../components/CoursesList/CoursesList";

import Course from "../components/Course/Course";
import { PATHS } from "../services/Consts";
import Redirect from "../components/Redirect/Redirect";
import Lesson from "../components/Lesson/Lesson";
import Tasks from "../components/Tasks/Tasks";
import axios from 'axios';

import arrow from "../img/arrow.png"

import style from "./MyLearning.module.css";
import { useState, useEffect } from "react";
import { Context } from "../App";
import { useContext } from "react";


const formatDateTime = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

function Layout() {

    const [moveUp, setMoveUp] = useState(false)
    const [todayLessons, setTodayLessons] = useState(null)
    const [homework, setHomework] = useState(null)
    const [lessons, setLessons] = useState(null)
    const [isShowHomework, setIsShowHomework] = useState(null)
    const [isShowLessonToday, setIsShowLessonToday] = useState(null)

    const location = useLocation()

    const activeMonthIndex = new Date().getMonth(); // +1
    const day = new Date().getDate();
    const year = new Date().getFullYear();

    useEffect(() => {
        
        const fetchLessons = async () => {
                
          const startDate = new Date(year, activeMonthIndex, 1, 0, 0, 0); 
          const endDate = new Date(year, activeMonthIndex + 1, 0, 23, 59, 59); 
    
          const startAt = formatDateTime(startDate);
          const endAt = formatDateTime(endDate);
          try {
            const response = await axios.get('https://extremumcourse.ru/api/notifications/make-in-time', {
              headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
              params: { startAt, endAt }
            });
            setLessons(response.data); 
            dataTodayLessons(day, activeMonthIndex + 1, year, response.data);
          } catch (error) {
            console.error('Ошибка при получении данных:', error);
          }
        };
    
        fetchLessons();
      }, [activeMonthIndex]);


      const hasEmptyFields = obj => Object.values(obj).includes('');

      function dataNextThreeDaysHomework(day, month, year, lessons) {
        const inputDate = new Date(year, month - 1, day);
    
        const tomorrow = new Date(inputDate);
        const dayAfterTomorrow = new Date(inputDate);
        const threeDaysFromNow = new Date(inputDate);
        
        tomorrow.setDate(tomorrow.getDate() + 1);
        dayAfterTomorrow.setDate(dayAfterTomorrow.getDate() + 2);
        threeDaysFromNow.setDate(threeDaysFromNow.getDate() + 3);
    
        function formatDate(dateString) {
            const date = new Date(dateString);
            return date.toLocaleDateString('ru-RU', {
                day: '2-digit',
                month: '2-digit'
            });
        }
    
        const filteredHomework = lessons
            .filter(lesson => {
                const deadlineDate = new Date(lesson.lessonDeadlineAt);
                return (deadlineDate >= tomorrow && deadlineDate <= threeDaysFromNow);
            })
            .map(lesson => ({
                lessonName: lesson.lessonName,
                courseName: lesson.courseName,
                deadlineDate: formatDate(lesson.lessonDeadlineAt)
            }));
        setHomework(filteredHomework);
        hasEmptyFields(filteredHomework) ? setIsShowHomework(false) : setIsShowHomework(true)
    }

      function dataTodayLessons(day, month, year, lessons) {
        dataNextThreeDaysHomework(day, month, year, lessons);
        const inputDate = new Date(year, month - 1, day); 

        function formatTime(dateString) {
            const date = new Date(dateString);
            return date.toTimeString().slice(0, 5);
        }

        const filteredLessons = lessons
            .filter(lesson => {
                const lessonDate = new Date(lesson.lessonStartAt);

                return lessonDate.getDate() === inputDate.getDate() &&
                       lessonDate.getMonth() === inputDate.getMonth() &&
                       lessonDate.getFullYear() === inputDate.getFullYear();
            })
            .map(lesson => ({
                lessonName: lesson.lessonName,
                courseName: lesson.courseName,
                lessonTime: formatTime(lesson.lessonStartAt)
            }));

        setTodayLessons(filteredLessons);
        hasEmptyFields(filteredLessons) ? setIsShowLessonToday(false) : setIsShowLessonToday(true)
    }
    return (
        <div>
            <div style={{
                width: "100%"
            }}>
                <Outlet/>
            </div>
            {(isShowHomework || isShowLessonToday) && (window.innerWidth > 768) && (location.pathname === "/mylearning") ? 
            <div className={style.clickToMoveContainer}>
                <div className={style.footerNotification}>
                    <div className={style.text1}>Ближайшие уроки и ДЗ</div>
                    <img onClick={() => {setMoveUp(!moveUp)}} className={style.arrow}src={arrow} alt="" />
                </div>

                <div className={ moveUp ? style.moveContainer : style.moveContainerActive}>
                    <div className={style.nearLessonContainer}>
                        <div style={{fontWeight: "700", fontSize: "18px", color: "#FFFFFF"}}>Ближайшие уроки</div>
                        {todayLessons.length > 0 ? (
                        todayLessons.map((lesson, index) => (
                            <div key={index} className={style.homework}>
                                <div className={style.left}>
                                    <div style={{fontWeight: "700", fontSize: "18px", color: "#FFFFFF"}}>{lesson.lessonName}</div>
                                    <div style={{fontWeight: "500", fontSize: "16px", color: "#FFFFFF"}}>{lesson.courseName}</div>
                                </div>
                                <div style={{fontWeight: "700", fontSize: "18px", color: "#FFFFFF"}}>{lesson.lessonTime}</div>
                            </div>
                        ))
                        ) : (
                            <div className={style.noLessons} style={{fontWeight: "700", fontSize: "18px", color: "#FFFFFF"}}>На сегодня нет занятий!</div>
                        )}
                        <div style={{fontWeight: "700", fontSize: "18px", color: "#FFFFFF"}}>Домашние задание</div>
                    {homework.length > 0 ? (
                        homework.map((lesson, index) => (
                            <div key={index} className={style.homework}>
                                <div className={style.left}>
                                    <div style={{fontWeight: "700", fontSize: "18px", color: "#FFFFFF"}}>{lesson.lessonName}</div>
                                    <div style={{fontWeight: "500", fontSize: "16px", color: "#FFFFFF"}}>{lesson.courseName}</div>
                                </div>
                                <div style={{fontWeight: "700", fontSize: "18px", color: "#FFFFFF"}}>До {lesson.deadlineDate}</div>
                            </div>
                        ))
                        ) : (
                            <div className={style.noHomework} style={{fontWeight: "700", fontSize: "18px", color: "#FFFFFF"}}>Все Д/З выполнено!</div>
                        )}
                    </div>
                </div>
            </div> 
            : null}
        </div>
    )
}

function MyLearning(props) {

    const context = useContext(Context);
    
    useEffect(() => {
        context.setMenuItem(0);
        context.setTopBarTitle("Моё обучение");
    },[]);

    return (
        <Routes>
            <Route element={<Layout/>}>
                <Route path="/"                             element={<CoursesList setMobileTitle={context.setTopBarTitle}/>} />
                <Route path="/:CourseId"                    element={<Course setMobileTitle={context.setTopBarTitle}/>}/>
                <Route path="/:CourseId/:LessonId/*"          element={<Lesson/>}/>
                {/* <Route path="/:CourseId/:LessonId/tasks"    element={<Tasks/>}/> */}
                <Route path={PATHS.other}                   element={<Redirect path = "/"/>}/>
            </Route>
        </Routes>
    )
}

export default MyLearning;