import { useEffect, useState } from 'react';
import Loading from "../Load/Loading";
import { useNavigate } from 'react-router-dom';
import { PATHS } from '../../services/Consts';

export default function Vk({onLoginSuccess}) {
  const [vkParams, setVkParams] = useState(null);
  const [loading, setLoading] = useState(true); 
  const navigate = useNavigate();
  const [token, setToken] = useState(null);
  

  useEffect(() => {
    const query = new URLSearchParams(window.location.search); 
    const params = {
      code: query.get('code'),
      state: query.get('state'),
      device_id: query.get('device_id'),
      redirect_uri: "https://extremumcourse.ru/vkauth", 
    };

    setVkParams(params); 
  }, []);

  useEffect(() => {
    if (vkParams) {
      const sendVkTokenRequest = async () => {
        try {
          const url = new URL('https://extremumcourse.ru/api/v2/account/auth/vk/token');
          
          url.searchParams.append('code', vkParams.code);
          url.searchParams.append('state', vkParams.state);
          url.searchParams.append('device_id', vkParams.device_id);
          url.searchParams.append('redirect_uri', vkParams.redirect_uri);

          const response = await fetch(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({}), 
          });

          if (response.ok) {
            const data = await response.json();
            onLoginSuccess(data.access_token)
            navigate(PATHS.empty, { replace: false });

          } else {
            console.error('Ошибка при отправке запроса на получение токена:', response.statusText);
          }
        } catch (error) {
          console.error('Ошибка при отправке запроса на получение токена:', error);
        } finally {
          setLoading(false);
        }
      };

      sendVkTokenRequest();
    }
  }, [vkParams]);

  if (loading) {
    return <Loading />; 
  }

  return (
      <Loading/>
  );
}
