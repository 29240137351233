import style from "./LogForms.module.css";
import { useEffect, useState } from "react";
import logo from "../../img/Logo.png";
import { useNavigate } from "react-router-dom";
import { Checkbox, FormControlLabel, Link, Typography } from "@mui/material";
import { PATHS } from "../../services/Consts";
import Loading from "../Load/Loading";
import politica from "../../Docs/politica.pdf";
import conditional from "../../Docs/Conditional.pdf";
import vk from "../../img/vk.png";
import axios from "axios";
function LogForms({ onLoginSuccess, onGetUserData, setUrl }) {
  const [successReg, setSuccessReg] = useState(false);

  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [isLogin, setIsLogin] = useState(true);
  const [isSignUp, setIsSignUp] = useState(false);
  const [registerLogin, setRegisterLogin] = useState("");
  const [registerPassword, setRegisterPassword] = useState("");
  const [registerRepeatPassword, setRegisterRepeatPassword] = useState("");
  const [isEmptyField, setIsEmptyField] = useState(false);
  const [isRepeatPassword, setIsRepeatPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isChecked, setIsChecked] = useState(false);

  const [loading, setLoading] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  const loginButtonStyle = { color: isLogin ? "black" : "rgba(0, 0, 0, 0.5)" };
  const registerButtonStyle = {
    color: isSignUp ? "black" : "rgba(0, 0, 0, 0.5)",
  };
  const validateMessage = {
    opacity: isEmptyField || isRepeatPassword || errorMessage ? "1" : "0",
  };

  const navigate = useNavigate();
  const handleRecoverPassword = () => {
    navigate(PATHS.recoverPassword, { replace: false });
  };

  let userData = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  };
  function handleChangeLogin() {
    setIsSignUp(true);
    setIsLogin(false);
    setIsEmptyField(false);
    setIsRepeatPassword(false);
    setLogin("");
    setPassword("");
    setErrorMessage("");
  }

  function handleChangeSignUp() {
    setIsLogin(true);
    setIsSignUp(false);
    setIsEmptyField(false);
    setIsRepeatPassword(false);
    setRegisterLogin("");
    setRegisterPassword("");
    setRegisterRepeatPassword("");
    setErrorMessage("");
  }

  const getVKAuthorizationLink = async () => {
    try {
      const response = await axios.get(
        "https://extremumcourse.ru/api/v2/account/auth/vk/authorize", //new
        {
          headers: {
            "Content-Type": "application/json",
          },
          params: {
            redirect_uri: "https://extremumcourse.ru/vkauth",
          },
        }
      );

      const { redirect_url } = response.data;
      setUrl(redirect_url);
      if (redirect_url) {
        window.location.href = redirect_url;
      }
    } catch (error) {
      console.error("Error fetching VK authorization link:", error);
    }
  };

  function handleSubmitLogin(e) {
    e.preventDefault();
    if (login.length > 0 && password.length > 0) {
      setLoading(true);
      fetch("https://extremumcourse.ru/api/v2/account/auth/email/login", { //new
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: login,
          password: password,
        }),
      })
        .then((response) => {
          setLoading(false);
          if (response.status === 200) {
            return response.json();
          } else if (response.status === 400) {
            throw new Error("Ошибка, данные неверны!");
          } else if (response.status === 406) {
            throw new Error("Требуется подтверждение аккаунта!");
          } else {
            throw new Error("Неправильный логин или пароль!");
          }
        })
        .then((data) => {
          localStorage.setItem('token', data.access_token);

          onLoginSuccess(data.access_token);
          setSuccessReg(true);
          localStorage.setItem('token', data.access_token);
          navigate(PATHS.empty, { replace: false });
        })
        .catch((error) => {
          setErrorMessage(error.message);
          console.error("Error:", error);
        });
    } else {
      setIsEmptyField(true);
    }
  }
  function handleSubmitRegister(e) {
    e.preventDefault();
    if (registerLogin.length === 0 || registerPassword.length === 0) {
      setIsEmptyField(true);
      setErrorMessage("Все поля должны быть заполнены.");
      return;
    } else {
      setErrorMessage("");
      setIsEmptyField(false);
      setIsRepeatPassword(false);
      navigate(PATHS.signUp, { replace: false });
      userData.firstName = registerLogin;
      userData.lastName = registerPassword;
      onGetUserData(userData);

    }
  }
  function handleCheckboxChange() {
    setIsChecked(!isChecked);
  }
  if (loading) {
    return <div className={style.loading}><Loading /></div>
  } else {
    if (isLogin) {
      if (width < 768) {
        return (
          <div className={style.main}>
            <div className={style.logo}>
              <img src={logo} alt="logo" />
            </div>
            <div className={style.container}>
              <div className={style.navContainer}>
                <div className={style.navLogContainer}>
                  <button
                    style={loginButtonStyle}
                    className={style.login}
                    onClick={handleChangeSignUp}>
                    Вход
                  </button>
                </div>
                <div className={style.navRegContainer}>
                  <button
                    style={registerButtonStyle}
                    className={style.signin}
                    onClick={handleChangeLogin}>
                    Регистрация
                  </button>
                </div>
              </div>
              <div className={style.formContainer}>
                <form className={style.form} onSubmit={handleSubmitLogin}>
                  <div className={style.loginInputContainer}>
                    <label className={style.loginLabel}>
                      Телефон или почта
                    </label>
                    <input
                      className={style.loginInput}
                      type="text"
                      onChange={(e) => setLogin(e.target.value)}
                      value={login}
                    />
                  </div>
                  <div className={style.passwordInputContainer}>
                    <label className={style.passwordLabel}>Пароль</label>
                    <input
                      className={style.passwordInput}
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <div
                      className={style.recoverPasswordSpan}
                      onClick={handleRecoverPassword}>
                      style={{cursor:  "pointer"}}
                      Забыли пароль?
                    </div>
                  </div>
                  <div
                    className={style.validateMessage}
                    style={validateMessage}>
                    {errorMessage ||
                      (isEmptyField && "Ошибка, проверьте введенные данные!")}
                  </div>
                  <button className={style.btnLogin} type="submit">
                    Войти
                  </button>
                </form>
                <div onClick={getVKAuthorizationLink} className={style.vkButton}>
                  <img src={vk} alt="" />
                  <div>ВКонтакте</div>
                </div>
              </div>
            </div>
            <div className={style.docContainer}>
              <a className={style.link} href={conditional}>
                Условия соглашения
              </a>
              <a className={style.link} href={politica}>
                Политика конфиденциальности
              </a>
            </div>
          </div>
        );
      } else {
        return (
          <div className={style.main}>
            <div className={style.logo}>
              <img src={logo} alt="logo" />
            </div>
            <div className={style.container}>
              <div className={style.navContainer}>
                <div className={style.navLogContainer}>
                  <button
                    style={loginButtonStyle}
                    className={style.login}
                    onClick={handleChangeSignUp}>
                    Вход
                  </button>
                </div>
                <div className={style.navRegContainer}>
                  <button
                    style={registerButtonStyle}
                    className={style.signin}
                    onClick={handleChangeLogin}>
                    Регистрация
                  </button>
                </div>
              </div>
              <div className={style.formContainer}>
                <form className={style.form} onSubmit={handleSubmitLogin}>
                  <div className={style.loginInputContainer}>
                    <label className={style.loginLabel}>
                      Телефон или почта
                    </label>
                    <input
                      className={style.loginInput}
                      type="text"
                      onChange={(e) => setLogin(e.target.value)}
                      value={login}
                    />
                  </div>
                  <div className={style.passwordInputContainer}>
                    <label className={style.passwordLabel}>Пароль</label>
                    <input
                      className={style.passwordInput}
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <span
                      className={style.recoverPasswordSpan}
                      onClick={handleRecoverPassword}>
                      Забыли пароль?
                    </span>
                  </div>
                  <div
                    className={style.validateMessage}
                    style={validateMessage}>
                    {errorMessage ||
                      (isEmptyField && "Ошибка, проверьте введенные данные!")}
                  </div>
                  <button className={style.btnLogin} type="submit">
                    Войти
                  </button>
                </form>
                <div
                  onClick={getVKAuthorizationLink}
                  className={style.vkButton}>
                  <img src={vk} alt="" />
                  <div>ВКонтакте</div>
                </div>
              </div>
              <div className={style.docContainer}>
                <a className={style.link} href={conditional} target="_blank">
                  Условия соглашения
                </a>
                <a className={style.link} href={politica} target="_blank">
                  Политика конфиденциальности
                </a>
              </div>
            </div>
          </div>
        );
      }
    } else if (isSignUp) {
      return (
        <div className={style.main}>
          <div className={style.logo}>
            <img src={logo} alt="logo" />
          </div>
          <div className={style.container}>
            <div className={style.navContainer}>
              <button
                style={loginButtonStyle}
                className={style.login}
                onClick={handleChangeSignUp}>
                Вход
              </button>
              <button
                style={registerButtonStyle}
                className={style.signin}
                onClick={handleChangeLogin}>
                Регистрация
              </button>
            </div>
            <div className={style.formContainer}>
              <form className={style.form} onSubmit={handleSubmitRegister}>
                <div className={style.loginInputContainer}>
                  <label className={style.loginLabel}>Имя</label>
                  <input
                    className={style.loginInput}
                    type="text"
                    onChange={(e) => setRegisterLogin(e.target.value)}
                    value={registerLogin}
                  />
                </div>
                <div className={style.passwordInputContainer}>
                  <label className={style.passwordLabel}>Фамилия</label>
                  <input
                    className={style.passwordInput}
                    type="text"
                    onChange={(e) => setRegisterPassword(e.target.value)}
                    value={registerPassword}
                  />
                </div>
                <div className={style.validateMessage} style={validateMessage}>
                  {errorMessage}
                </div>
                <div className={style.checkBoxContainer}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={{
                          "& .MuiCheckbox-root": {
                            color: "green", // Цвет фона чекбокса
                          },
                          "& .Mui-checked": {
                            color: "green", // Цвет галочки в чекбоксе при активном состоянии
                          },
                          "& .MuiCheckbox-root:hover": {
                            backgroundColor: "green", // Цвет фона чекбокса при наведении
                          },
                        }}
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                        color="primary"
                      />
                    }
                    label={
                      <Typography variant="body2">
                        <div className={style.checkboxText}>
                          Регистрируясь на платформе, я принимаю{" "}
                          <span style={{ textDecoration: "underline" }}>
                            условия соглашения
                          </span>{" "}
                          и{" "}
                          <span style={{ textDecoration: "underline" }}>
                            политики конфиденциальности
                          </span>
                          , а также даю согласие на обработку персональных
                          данных.
                        </div>
                      </Typography>
                    }
                  />
                </div>
                <button
                  className={style.btnLogin}
                  {...{ disabled: !isChecked }}
                  type="submit">
                  Далее
                </button>
                <div className={style.signUpVkContainer}>
                  <span>Или с помощью</span>
                </div>
                <div onClick={getVKAuthorizationLink} className={style.vkButton}>
                  <img src={vk} alt="" />
                  <div>ВКонтакте</div>
                </div>
              </form>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default LogForms;
