import { Outlet } from "react-router-dom";
import Menu from "../components/Menu/Menu";
import TopBar from "../components/TopBar/TopBar";

import style from "./MainLayout.module.css";
import { useState } from "react";

function MainLayout() {

    const [IS_VERTICAL, SET_IS_VERTICAL] = useState(window.innerHeight / window.innerWidth > 1.25)
    window.addEventListener('resize', (event)=>{
        SET_IS_VERTICAL(window.innerWidth < 930)
    }, false)
    
    const [menuIsOpen, setMenuIsOpen] = useState(false)
    const [mobileTitle, setMobileTitle] = useState("");

    function SwitchMenuBtn() {
        return (
            <div className={style.burgerMenu + (menuIsOpen ? " " + style.white : " " + style.purple)} onClick={()=>{setMenuIsOpen(!menuIsOpen)}}>
                <div className={style.lineBurgerContainer}>
                    <div className={style.lineBurger + (menuIsOpen ? " " + style.purple : " " + style.white)}></div>
                    <div className={style.lineBurger + (menuIsOpen ? " " + style.purple : " " + style.white)}></div>
                    <div className={style.lineBurger + (menuIsOpen ? " " + style.purple : " " + style.white)}></div>
                </div>
            </div>
        )
    }

    return (
        <>
            {
                IS_VERTICAL ?
                <>
                <div className={style.topBar}
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignContent: "center",
                        justifyContent: "space-between"
                        
                    }}
                    >
                    <TopBar mobileTitle={mobileTitle}/>
                    <SwitchMenuBtn/>
                </div>
                <div className={style.mobileMainContent}>    
                    <Outlet context={[mobileTitle, setMobileTitle]}/>
                </div>
                {
                    menuIsOpen ? <div style={{
                        position: "fixed",
                        top: "0",
                        width: "100%",
                        height: "100vh"
                    }} onClick={()=>{setMenuIsOpen(false)}}>

                    </div> : <></>
                }
                <div className={style.mobileMenuContainer  + (menuIsOpen ? " " + style.maxMenu : "")}>
                    <Menu menuClassName={style.mobileMenu} menuButtonClassName={style.mobileButton} isMobile={true} hideMenu={()=>{setMenuIsOpen(false)}}/>
                </div>
                </>
                :
                <>

                <div className={style.topBar}>
                    <TopBar mobileTitle={mobileTitle}/>
                </div>
                <div className={style.flexWrapper}>
                    <div className={style.menuContainer}>
                        <Menu menuClassName={style.menu}/>
                    </div>
                    <div className={style.mainContent}>    
                        <Outlet context={[mobileTitle, setMobileTitle]}/>
                    </div>
                </div>

                </>
            }
        </>
    )
}

export default MainLayout;