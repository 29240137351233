import style from "./Chat.module.css";
import send from "../../img/send.png"
import clip from "../../img/clip.png"
function Chat() {
    
    return (
        <div className={style.mainContainer}>

            <div className={style.chatContainer}>
                <div className={style.chatField}>
                    <div className={style.curator}>
                    </div>
                    <div className={style.user}></div>
                </div>
                <div className={style.footChat}>
                    <form action="">
                        <textarea className={style.inputText}  type="text" placeholder="Опишите свой вопрос..." />
                        <div className={style.inputFile}>
                          <img src={clip} alt="" />
                          <div>Прикрепить файл</div>
                        </div>
                        <div className={style.sendButton}><img src={send} alt="" /></div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Chat;