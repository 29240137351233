import { useState } from "react"
import { useNavigate } from "react-router-dom";

import style from "./MenuButton.module.css";

function MenuButton(props) {
    const navigate = useNavigate();

    const onClick = (event) => {
        event.preventDefault();
        props.onClick(props.index)
        navigate(props.path)
        
    }

    return (
        <div className={style.menuButton + " " + (props.isPushed ? style.pushed : style.common)} onClick={onClick}>
            <div className={style.iconContainer}>
                {props.icon}
            </div>
            <div className={style.btnTextContainer}>
                {props.text}
            </div>
        </div>
    )
}

export default MenuButton;