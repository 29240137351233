import style from "./RecoverPassword.module.css";
import logo from "../../img/Logo.png"
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { PATHS } from '../../services/Consts';
function RecoverPassword({onGetEmail}) {


    const [email, setEmail] = useState("")
    const [isEmptyField, setIsEmptyField] = useState(false)
    const validateMessege = {opacity: isEmptyField ? "1" : "0"}
    const emptyField = "Ошибка! Введите корректные данные!"
    
    const navigate = useNavigate()


    const handleConfirmEmail = () => {
        if (isEmptyField === false) {
        navigate("../" + PATHS.newPassword, {replace: true})
        
        }
        else {
            setIsEmptyField(true)
        }
    
}
    function handleSubmit(e) {
        e.preventDefault();
        if (email.length > 0) {
            onGetEmail(email)
            setEmail("")
            setIsEmptyField(false)
            handleConfirmEmail()
        } else {
            setIsEmptyField(true)
        }

    }
    return (
        
        <div className={style.main}>
            <div className={style.logo}>
                <img src={logo} alt="logo" />
            </div>
            <div className={style.container}>
                <div className={style.titleContainer}>Восстановление пароля</div>
                <div className={style.formContainer}>
                <form className={style.form} onSubmit={handleSubmit}>

                    <div className={style.emailInputContainer}>
                        <label className={style.emailLabel}>Введите почту</label>
                        <input className={style.emailInput} 
                        type="text"
                        onChange={e => setEmail(e.target.value)}
                        value={email}
                        />
                        <div className={style.text2}>Отправим Вам сообщение для сброса пароля</div>
                        <div className={style.validateMessege} style={validateMessege}>{emptyField}</div>
                    </div>
                    
                    <button className={style.btnPost} onClick={handleSubmit} >Отправить</button>
                </form>
                
            </div>

        </div>
    </div>
    )
}

export default RecoverPassword;