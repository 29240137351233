import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import CourseCard from "../CourseCard/CourseCard";
import Loading from "../Load/Loading";

import API from "../../services/API";
import style from "./CourseList.module.css"



function CoursesList(props) {
    
    const [courses, setCourses] = useState([]);
    const [isLoading, setIsLoading] = useState(true)

    const navigate = useNavigate();
    function OpenCourse(id) {
        navigate("" + id, {relative: "path"})
    }


    useEffect(()=>{
        async function getCourses() {
            setCourses(await API.GetMyCurses());
            
            setIsLoading(false);
        }
        getCourses();
    }, [])

    props.setMobileTitle("Мое обучение")

    if (isLoading) {
        return (
            <Loading/>
        )
    }

    return (
        <>
            {(courses.length > 0)
            ? courses.map((course, index) => {
                return (
                    <CourseCard onClick={OpenCourse} key = {index} course={course}/>
                )
            })
            : <div className={style.courseList}>
                <div className={style.title} style={{fontSize: "30px", fontWeight: "600", color: "rgba(108, 101, 253)"}}>Вы не начали обучение</div>
                <div className={style.title2} style={{fontSize: "18px", fontWeight: "400", color: "rgba(108, 101, 253)"}}>Выберите подходящий курс в разделе <span style={{fontWeight: "600", textDecoration: "underline"}}>Все курсы</span></div>
            </div>
            }
        </>
    )

}

export default CoursesList;