import { useEffect, useState } from "react";
//import { VscSearch } from "react-icons/vsc";

import Lesson from "../Lesson/Lesson";
import CourseCard from "../CourseCard/CourseCard";
import LessonCard from "../LessonCard/LessonCard";

import API from "../../services/API";

import style from "./Course.module.css";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../Load/Loading";

function Course(props) {
    const {CourseId} = useParams();
    const navigate = useNavigate();

    const [lessons, setLessons] = useState([]);
    const [visibleLessons, setVisibleLessons] = useState([]);
    const [course, setCourse] = useState({});

    const [courseIsLoading, setCourseIsLoading] = useState(true);
    const [lessonsAreLoading, setLessonsAreLoading] = useState(true);

    const [search, setSearch] = useState("");

    props.setMobileTitle(course.title);

    function searchHandle(event) {
        event.preventDefault();
        setSearch(event.target.value);
        setVisibleLessons(lessons.filter(lesson => {
            return lesson.title.toLowerCase().includes(event.target.value.toLowerCase());
        }))
    }

    function handleLessonClick(id) {
        navigate("" + id, {relative: "path"})
    }

    async function getCourse() {
        let result = await API.GetMyCourse(CourseId);
        setCourse(result);
        setCourseIsLoading(false);

        result = await API.GetMyCourseLessons(CourseId, result.totalLessons, 0)
        setLessons(result);
        setVisibleLessons(result)
        setLessonsAreLoading(false);
    }

    function openCourseChat() {
        course.chatLink ? window.open(course.chatLink, '_blank') : alert("У этого курса нет чата");
    }

    useEffect(
        () => {
            getCourse()
    }, []);

    if (courseIsLoading) {
        return (
                    <Loading/>
        )
    }

    return (
        <div>

        <div className={style.Course}>
            <div className={style.top}>
                <div className={style.title}>{course.title}</div>
                <div className={style.progress}>
                    <div className={style.progressText}>{course.completedLessons + "/" + course.totalLessons} уроков</div>
                    <div className={style.progressBar}><div className={style.completedBar} style={{width: course.completedLessons / course.totalLessons * 100 + "%"}}></div></div>
                </div>
                <div className={style.search}>
                    <input className={style.searchInput} type="text" placeholder="Найти урок" value={search} onChange={searchHandle}></input>
                    <span className={style.searchIcon}>
                        <svg xmlns="https://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16"><path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" /></svg>
                    </span>
                </div>
                <div className={style.filter}>
                    <div onClick={openCourseChat} style={{cursor: "pointer"}}>Чат курса</div>
                </div>
            </div>
            <div className={style.lessonsGrid}>
                {visibleLessons ? visibleLessons.map((lesson, index) => {
                    return (
                        <LessonCard key = {index} lesson = {lesson} number = {lessons.indexOf(lesson) + 1} onClick={()=>{handleLessonClick(lesson.lessonId)}}/>
                    )
                }) : <></> } 
            </div>
        </div>
        </div>
    )
}

export default Course;