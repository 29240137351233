import style from "./ConfirmEmail.module.css";
import { useState, useRef, useEffect } from 'react';
import logo from "../../img/Logo.png"
import { useNavigate } from "react-router-dom";
import { PATHS} from "../../services/Consts";

function ConfirmEmailPswd({email,  newPassword}) {     
    // Состояния для хранения states обработки форм входа
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');

    // Состояния для хранения states обработки форм 
    const [num1, setNum1] = useState("");
    const [num2, setNum2] = useState("");
    const [num3, setNum3] = useState("");
    const [num4, setNum4] = useState("");
    const [num5, setNum5] = useState("");
    const [num6, setNum6] = useState("");

    const inputRefs = useRef([]);

    // Состояния для изменения вход/регистрации
    const [isLogin, setIsLogin] = useState(false);
    const [isSignUp, setIsSignUp] = useState(true)

    // Состояник для хранения states проверки валидности
    const [isEmptyField, setIsEmptyField] = useState(false)

    // Состояния для таймера и кнопки повторной отправки
    const [timer, setTimer] = useState(0);
    const [isResendEnabled, setIsResendEnabled] = useState(true);

    const loginButtonStyle = {color: isLogin ? 'black' : 'rgba(0, 0, 0, 0.5)'};
    const registerButtonStyle = {color: isSignUp ? 'black' : 'rgba(0, 0, 0, 0.5)'};

    const validateMessege = {opacity: isEmptyField ? "1" : "0"}

    let errorMes = "Ошибка, проверьте введеные данные!";

    const navigate = useNavigate();
    const handleChangePassword = () => {
        navigate("../" + PATHS.login, {replace: false})
    }

    function handleChangeLogin() {
        setIsSignUp(true);
        setIsLogin(false);
        setIsEmptyField(false);
    }

    function handleChangeSignUp() {
        setIsLogin(true);
        setIsSignUp(false);
        setIsEmptyField(false);
    }
    
    function handleSubmitLogin(e) {
        e.preventDefault();
        if (login.length > 0 && password.length > 0) {
            setIsEmptyField(false);
        } else {
            setIsEmptyField(true);
        }
    }

    function handleSubmitEmail(e) {
        e.preventDefault();
        let checkValue = num1 + num2 + num3 + num4 + num5 + num6;

        if (num1.length === 0 || num2.length === 0 || num3.length === 0 || num4.length === 0 || num5.length === 0 || num6.length === 0 ) {
            setIsEmptyField(true);
        } else {
            fetch("https://extremumcourse.ru/api/v2/account/auth/email/verification", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem("token"),
                },
                body: JSON.stringify({ code: checkValue })
            })
            .then(response => {
                if (response.status === 200) {
                    alert("Пароль успешно изменен");
                    navigate("../", {replace: false})
                    setNum1('');
                    setNum2('');
                    setNum3('');
                    setNum4('');
                    setNum5('');
                    setNum6('');
                    setIsEmptyField(false);
                    return response.json();
                } else if (response.status === 400) {
                    errorMes = "Неправильный код подтверждения"
                    return new Error("Неправильный код подтверждения")
                } else if (response.status === 401) {
                    return new Error("Unauthorized")
                }  else if (response.status === 429) {
                    errorMes = "Код просрочен"
                    return new Error("Код просрочен")
                } else {
                    return new Error("Неизвестная ошибка")
                }
            })
            
        }
    }

    const handleChange = (e, index) => {
        const value = e.target.value;
        if (value.length >= 1) {
            if (index < inputRefs.current.length - 1) {
                inputRefs.current[index + 1].focus();
            }
        }
        switch(index) {
            case 0: setNum1(value); break;
            case 1: setNum2(value); break;
            case 2: setNum3(value); break;
            case 3: setNum4(value); break;
            case 4: setNum5(value); break;
            case 5: setNum6(value); break;
            default: break;
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === 'Backspace' && !e.target.value) {
            if (index > 0) {
                inputRefs.current[index - 1].focus();
            }
        }
    };

    const handleResendCode = () => {
        fetch('https://extremumcourse.ru/api/v2/account/auth/email/repair', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: email,
                new_password: newPassword,
            }
            ),
        })
        .then(response => {
            if (response.status === 200) {
                return response.json();
            } else if (response.status === 400) {
                return new Error("Недопустимое время отправки кода", email, newPassword);
            } else {
                return new Error("Неизвестная ошибка");
            }
        } )
        setIsResendEnabled(false);
        setTimer(60);
    };

    useEffect(() => {

        if (timer > 0) {
            const countdown = setTimeout(() => setTimer(timer - 1), 1000);
            return () => clearTimeout(countdown);
        } else {
            setIsResendEnabled(true);
        }

    }, [timer]);

    if (isLogin === true) {
        return (
            <div className={style.main}>
                <div className={style.logo}>
                    <img src={logo} alt="logo" />
                </div>
                <div className={style.container}>
                    <div className={style.navContainer}>
                        
                    </div>
                    <div className={style.formContainer}>
                        <form className={style.form} onSubmit={handleSubmitLogin}>
                            <div className={style.loginInputContainer}>
                                <label className={style.loginLabel}>Телефон или почта</label>
                                <input className={style.loginInput} 
                                type="text"
                                onChange={e => setLogin(e.target.value)}
                                value={login}
                                />
                            </div>
                            <div className={style.passwordInputContainer}>
                                <label className={style.passwordLabel}>Пароль</label>
                                <input className={style.passwordInput}
                                type="password"
                                value={password}
                                onChange={e => setPassword(e.target.value)}/>
                            </div>
                            <div className={style.validateMessege} style={validateMessege}>{errorMes}</div>
                            <button className={style.btnLogin} onClick={handleSubmitLogin} >Войти</button>
                            <button className={style.recoverPassword}>Восстановить пароль</button>
                        </form>
                    </div>
                </div>
            </div>
        )
    } else if (isSignUp === true) { // Регистрация
        return (
            <div className={style.main}>
                <div className={style.logo}>
                    <img src={logo} alt="logo" />
                </div>
                <div className={style.container}>
                    <div className={style.navContainer}>
                       <div className={style.title1}>Подтверждение почты</div>
                    </div>
                    <div className={style.titleContainer}>
                        <div className={style.title}>Мы отправили код на почту</div>
                        <div className={style.email}>{email}</div>
                    </div>
                    <div className={style.formContainer}>
                        <form className={style.formNum} onSubmit={handleSubmitEmail}>
                            {Array.from({ length: 6 }).map((_, index) => (
                                <div key={index} className={style[`num${index + 1}InputContainer`]}>
                                    <input
                                        className={style[`num${index + 1}Input`]}
                                        type="number"
                                        value={[num1, num2, num3, num4, num5, num6][index]}
                                        max="9"
                                        min="0"
                                        onChange={e => handleChange(e, index)}
                                        onKeyDown={e => handleKeyDown(e, index)}
                                        ref={el => inputRefs.current[index] = el}
                                    />
                                </div>
                            ))}
                        </form>
                        <div className={style.titleMes}>Введите код для подтверждения своей почты</div>
                        <div className={style.validateMessege} style={validateMessege}>{(isEmptyField) ? errorMes : "Пасхалка"}</div>
                        <button className={style.btnPost} onClick={handleSubmitEmail}>Подтвердить</button>
                        {isResendEnabled ? (
                            <button className={style.btnRepeat} onClick={handleResendCode}>Отправить повторно</button>
                        ) : (
                            <div className={style.timerMessage}>
                                Код повторно отправлен {`00:${timer < 10 ? '0' : ''}${timer}`}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

export default ConfirmEmailPswd;
