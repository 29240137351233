import './App.css';
import * as React from "react";
import { BrowserRouter, Routes, Route, Navigate} from "react-router-dom";

import MainLayout from './scenes/MainLayout';
import Profile from './scenes/Profile';
import MyLearning from './scenes/MyLearning';
import AllCourses from './scenes/AllCourses';
import Support from './scenes/Support';
import TimeTable from './scenes/TimeTable';
import PlansMobile from './scenes/PlansMobile';
import ChatMobile from "../src/components/ChatMobile/ChatMobile";
import LogForms from './components/LogForms/LogForms';
import RecoverPassword from './components/LogForms/RecoverPassword';
import NewPassword from './components/LogForms/NewPassword';
import ConfirmEmail from './components/LogForms/ConfirmEmail';

import PlayGround from './scenes/PlayGround';
import { PATHS } from './services/Consts';
import SignUp from './components/LogForms/SignUp';
import ConfirmEmailPswd from './components/LogForms/ConfirmEmailPswd';
import Basket from './scenes/Basket';
import Invite from './scenes/Invite';
import NotificationMobilePage from './scenes/NotificationMobilePage';
import Vk from './components/Vk/Vk';
import { useState } from 'react';
import defaultAvatar from "../src/img/photo_5406613332124882751_m.jpg"

export const Context = React.createContext(null);

function App() {
    const [isLogin, setIsLogin] = React.useState(false);
    const [email, setEmail] = React.useState("");
    const [signupData, setSignupData] = React.useState({});
    const [planData, setPlanData] = React.useState(null)
    const [courseData, setCourseData] = React.useState(null)
    const [width, setWidth] = React.useState(window.innerWidth);
    const [courseImg, setCourseImg] = React.useState(null)
    const [url, setUrl] = React.useState(null)
    const [userData, setUserData] = React.useState(null);


    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [avatar, setAvatar] = useState(defaultAvatar);
    const [topBarTitle, setTopBarTitle] = useState();
    const [menuItem, setMenuItem] = useState(0);
    const [newPassword, setNewPassword] = useState("");

    React.useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            setIsLogin(true);
        } else {
            setIsLogin(false);
        }
    }, []);

    const handleLoginSuccess = (token) => {
        setIsLogin(true);
        localStorage.setItem('token', token);
    };

    return (
        <BrowserRouter>
            <Context.Provider value={{
                firstName, setFirstName,
                lastName, setLastName,
                avatar, setAvatar,
                topBarTitle, setTopBarTitle,
                menuItem, setMenuItem,
            }}>
            <Routes>
                {isLogin ? (
                   
                    <>
                        <Route path={PATHS.empty} element={<MainLayout />}> 
                            <Route path={PATHS.myLearning + PATHS.other} element={<MyLearning />} />
                            <Route path={PATHS.allCourses} element={<AllCourses setCourseImg={setCourseImg} getPlanData={setPlanData} getCourseData={setCourseData}/>} />
                            <Route path={PATHS.plans} element={<PlansMobile courseImg={courseImg} getPlanData={setPlanData}  courseData={courseData}/>} />
                            <Route path={PATHS.timeTable} element={<TimeTable />} />
                            <Route path={PATHS.support} element={<Support />} />
                            <Route path={PATHS.profile} element={<Profile />} />
                            <Route path={PATHS.basket} element={<Basket planData={planData} />} />
                            <Route path={PATHS.invite} element={<Invite/>} />
                            <Route path={PATHS.chat} element={<ChatMobile/>}/>
                            <Route path={PATHS.notification} element={<NotificationMobilePage/>}/>
                            <Route path="/" element={<Navigate to={PATHS.myLearning}/>} />
                        </Route>
                    </>
                ) : (
                    <>
                        <Route path={PATHS.login} element={<LogForms setUrl={setUrl} onLoginSuccess={handleLoginSuccess} onGetUserData={setSignupData} />} />
                        <Route path={PATHS.signUp} element={<SignUp setUrl={setUrl} onGetEmail={setEmail} setUserData={setUserData} onLoginSuccess={handleLoginSuccess} userData={signupData}/>} />
                        <Route path={PATHS.recoverPassword} element={<RecoverPassword onGetEmail={setEmail} />} />
                        <Route path={PATHS.confirmEmailPswd} element={<ConfirmEmailPswd newPassword={newPassword} email={email} />} />
                        <Route path={PATHS.confirmEmail} element={<ConfirmEmail userData={userData} email={email}/>} />
                        <Route path={PATHS.newPassword} element={<NewPassword email={email} setNewPassword={setNewPassword}/>} />
                        <Route path="*" element={<Navigate to={PATHS.login} replace />} />
                        <Route path="playground" element={<PlayGround />} />
                        <Route path={PATHS.vkauth} element={<Vk url={url} onLoginSuccess={handleLoginSuccess}/> } />
                    </>
                )}
            </Routes>
        </Context.Provider>
        </BrowserRouter>
    );
}

export default App;
