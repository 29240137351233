function CalendarIcon(props) {

    return (
        <svg width="38" height="33" viewBox="0 0 38 33" fill="none" xmlns="https://www.w3.org/2000/svg">
            <path d="M34.0002 5.54163H4.00016C3.07969 5.54163 2.3335 6.30647 2.3335 7.24996V29.4583C2.3335 30.4018 3.07969 31.1666 4.00016 31.1666H34.0002C34.9206 31.1666 35.6668 30.4018 35.6668 29.4583V7.24996C35.6668 6.30647 34.9206 5.54163 34.0002 5.54163Z" stroke={props.mainColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M10.667 2.125V8.95833M19.8337 16.6458H10.667M27.3337 23.4792H10.667M27.3337 2.125V8.95833" stroke={props.mainColor} strokeWidth="2" strokeLinecap="round"/>
        </svg>
    )
}

export default CalendarIcon;