import { useEffect, useState } from "react";
import API from "../../services/API";

import style from "./CourseCard.module.css";
import imgPlaceholder from "../../img/courseimg.png";

function CourseCard(props) {

    const [image, setImage] = useState(null);
    const [loading, setLoading] = useState(true);

    const handleClick = () => {
        props.onClick(props.course.courseId)
    }

    useEffect(() => {
        async function getImage()
        {
            if (props.course.hasImage)
            {
                setLoading(true);
                let img = await API.GetMyCourseImage(props.course.courseId);
                if (img) {
                    setImage(await URL.createObjectURL(img));
                } else {
                    setImage(imgPlaceholder)
                }
            } else {
                setImage(imgPlaceholder)
            }
            setLoading(false);
        }
        getImage()
    }, []);

    return (
        <div className={style.courseCard} onClick={handleClick}>
                
                    {
                        props.course.hasImage ? loading
                                                    ? <div className={style.courseCardImage} style={{
                                                        backgroundColor: "rgba(223, 222, 255, 1)",
                                                        }}> </div>
                                                    : <img className={style.courseCardImage} alt="courseImage" src={image}></img>
                        : <img className={style.courseCardImage} alt="courseImage" src={image}/>
                    }
              
                <div className={style.textContainer}>
                    <div className={style.title}>
                        {props.course.title}
                    </div>
                    <div className={style.description}>
                        {props.course.description}
                    </div>
                    <div className={style.progress}>
                        <div className={style.progressText}>
                            {props.course.completedLessons + "/" + props.course.totalLessons + " уроков"}
                        </div>
                        <div className={style.progressBar}>
                            <div className={style.completedBar} style={{
                                width: props.course.completedLessons / props.course.totalLessons * 100 + "%",
                            }}>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    )
}

export default CourseCard;