import style from "./Basket.module.css";
import img from "../img/courseimg.png";
import tick from "../img/tick2.png"
import { useState } from "react";
import star from "../img/Star.png"
import Radio from '@mui/material/Radio';
import { useRadioGroup } from '@mui/material/RadioGroup';
import Chat from "../components/Chat/Chat";
import { IoMdClose } from "react-icons/io";
import Mark from "../components/Mark/Mark";

function Basket({planData}) {


    const data = {
        image: planData.courseImage,
        title: planData.courseTitle,
        plan: planData.plan.title,
        description: planData.plan.descriptionItems,
        costCourse: planData.plan.cost,
        costCoursebeforeSale: planData.plan.lastCost,
        costMounth: planData.plan.costPerBlock,
        costMounthbeforeSale: planData.plan.lastCostPerBlock,
    };
    const [selected, setSelected] = useState(1)
    const [payData, setPayData] = useState(data.costCourse)
    const [width, setWidth] = useState(window.innerWidth);
    const [isOpen, setIsOpen] = useState(false)
    const [isMarkOpen, setIsMarkOpen] = useState(false)
    const [textArea, setTextArea] = useState(null)



    const handleClick = (id) => {
        setSelected(id);
        if (id === 1) {
            setPayData(data.costCourse)
        } else {
            setPayData(data.costMounth)
        }
      };




    if (width > 768) {
    return(
        <div className={style.mainContainer}>
            <div className={style.lefContainer}>
                <div className={style.headerContainer}>
                    <div className={style.imgContainer}>
                        <img src={data.image} alt="course img" />
                    </div>
                    <div className={style.descriptionContainer}>
                        <div className={style.title}>{data.title}</div>
                        <div className={style.typePlan}>Тариф: <span className={style.plan}>{data.plan}</span></div>
                        <button onClick={() => {setIsOpen(true)}} style={{cursor: "pointer"}} className={style.btnQuestion}>Задать вопрос</button>
                    </div>
                    <div>
            {isOpen && (
    <div className={style.modal2} onClick={() => setIsOpen(false)}>
        <div className={style.modalContent2} onClick={(e) => e.stopPropagation()}>
                <Chat/>
        </div>
        <div className={style.close2} onClick={() => {setIsOpen(false); setIsMarkOpen(true)}}><IoMdClose /></div>
    </div>
)}          </div>

            <div>
            {isMarkOpen && (
                <div className={style.modal3} onClick={() => setIsOpen(false)}>
                <div className={style.modalContent3} onClick={(e) => e.stopPropagation()}>
                        <Mark setTextArea={setTextArea}/> 
                </div>
                <div className={textArea ? style.close3 : style.close32} onClick={() => {setIsMarkOpen(false)}}><IoMdClose /></div>
            </div>
            )}
            </div>
                </div>
                <div className={style.bodyContainer}>
                    {data.description.map((item, index) => <div className={style.descriptionItems}><img src={tick} alt="" width="22" height={22}/><div key={index} className={style.descriptionItem}>{item}</div></div>)}
                </div>
            </div>
            {data.plan !== "Самоучка" ? (            <div className={style.rightContainer}>
                <div className={style.courseContainer}
                onClick={() => handleClick(1)}
                style={{
                outline: selected === 1 ? '4px solid #6A65FD' : '1px solid #6B65FD80',
                cursor: 'pointer'
                }}>
                    <div className={style.left}>
                        <div>Курс</div>
                        <div className={style.benefitContainer}>
                            <div className={style.benefitTitle}>Выгодно</div>
                            <img className={style.benefitLogo} src={star} alt="" />
                        </div>
                    </div>
                    <div className={style.priceContainer}>
                        <div className={style.price}>{data.costCourse} ₽</div>
                        <div className={style.priceBeforeSale}>{data.costCoursebeforeSale} ₽</div>
                    </div>
                </div>
                <div className={style.mounthContainer}
                                onClick={() => handleClick(2)}
                                style={{
                                outline: selected === 2 ? '4px solid #6A65FD' : '1px solid #6B65FD80',
                                cursor: 'pointer'
                                }}>
                    <div className={style.left2}>
                        <div>Месяц</div>
                        <div className={style.priceContainer}>
                            <div className={style.price}>{data.costMounth} ₽</div>
                            <div className={style.priceBeforeSale}>{data.costMounthbeforeSale} ₽</div>
                        </div>
                    </div>
                </div>
                <div className={style.payContainer}>
                    <div className={style.upContainerPay}>
                        <div className={style.infoContainerPay}>
                            <div style={{fontSize: "20px", fontWeight: "600"}}>Оплата</div>
                            <div className={style.priceContainerPay}>
                                <div style={{fontSize: "16px", fontWeight: "500"}}>Сумма заказа</div>
                                <div style={{fontSize: "16px", fontWeight: "500"}}>{payData}₽</div>
                            </div>
                            <input
                            className={style.promo} 
                            type="number"
                            placeholder="Ввести промокод" />
                        </div>
                    </div>
                    <div className={style.downContainerPay}></div>
                        <div className={style.infoPayContainer}>
                            <div style={{fontSize: "20px", fontWeight: "600"}}>Итого</div>
                            <div style={{fontSize: "24px", fontWeight: "600"}}>{payData}₽</div>
                        </div>
                        <button className={style.btnPay}>Оплатить {payData}₽</button>
                </div>
            </div>) : (
                            <div className={style.rightContainer}>
                            <div className={style.courseContainer}
                            onClick={() => handleClick(2)}
                            style={{
                            outline: selected === 2 ? '4px solid #6A65FD' : '1px solid #6B65FD80',
                            cursor: 'pointer'
                            }}>
                                <div className={style.left}>
                                    <div>Курс</div>
                                    <div className={style.benefitContainer}>
                                        <div className={style.benefitTitle}>Выгодно</div>
                                        <img className={style.benefitLogo} src={star} alt="" />
                                    </div>
                                </div>
                                <div className={style.priceContainer}>
                                    <div className={style.price}>{data.costMounth} ₽</div>
                                    <div className={style.priceBeforeSale}>{data.costMounthbeforeSale} ₽</div>
                                </div>
                            </div>
                            <div className={style.payContainer}>
                                <div className={style.upContainerPay}>
                                    <div className={style.infoContainerPay}>
                                        <div style={{fontSize: "20px", fontWeight: "600"}}>Оплата</div>
                                        <div className={style.priceContainerPay}>
                                            <div style={{fontSize: "16px", fontWeight: "500"}}>Сумма заказа</div>
                                            <div style={{fontSize: "16px", fontWeight: "500"}}>{payData}₽</div>
                                        </div>
                                        <input
                                        className={style.promo} 
                                        type="number"
                                        placeholder="Ввести промокод" />
                                    </div>
                                </div>
                                <div className={style.downContainerPay}></div>
                                    <div className={style.infoPayContainer}>
                                        <div style={{fontSize: "20px", fontWeight: "600"}}>Итого</div>
                                        <div style={{fontSize: "24px", fontWeight: "600"}}>{payData}₽</div>
                                    </div>
                                    <button className={style.btnPay}>Оплатить {payData}₽</button>
                            </div>
                        </div>
            )}

        </div>
        
    )
} else if (width <= 768) {
    return (
        <div className={style.mainContainer}>
            <div className={style.lefContainer}>
                <div className={style.headerContainer}>
                    <div className={style.imgContainer}>
                        <img src={data.image} alt="course img" />
                    </div>
                    <div className={style.descriptionContainer}>
                        <div className={style.title}>{data.title}</div>
                        <div className={style.typePlan}>Тариф: <span className={style.plan}>{data.plan}</span></div>
                    </div>
                </div>
            </div>
            {data.plan !== "Самоучка" ? (            <div className={style.rightContainer}>
                <div className={style.courseContainer}
                onClick={() => handleClick(1)}
                style={{
                outline: selected === 1 ? '2px solid #6A65FD' : '1px solid #6B65FD80',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center'
                }}>

                    <div className={style.left}>
                        <div style={{marginLeft: "0", fontSize: "16px", fontWeight: "600"}}>Курс</div>
                        <Radio
                        checked={selected === 1}
                        onChange={() => handleClick(1)}
                        value="1"
                        name="plan"
                        sx={{
                            color: 'rgba(106, 101, 253, 1)',
                            '&.Mui-checked': {
                                color: 'rgba(106, 101, 253, 1)',
                            },
                        }}
                        size="large" 
                    />
                    </div>
                    <div className={style.priceContainer}>
                        <div className={style.price}>{data.costCourse} ₽</div>
                        <div className={style.priceBeforeSale}>{data.costCoursebeforeSale} руб</div>
                    </div>
            </div>

            <div className={style.mounthContainer}
                onClick={() => handleClick(2)}
                style={{
                outline: selected === 2 ? '2px solid #6A65FD' : '1px solid #6B65FD80',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center'
                }}>
                    
                    <div className={style.left2}>
                        <div style={{marginLeft: "0", fontSize: "16px", fontWeight: "600"}}>Месяц</div>
                        <Radio
                        checked={selected === 2}
                        onChange={() => handleClick(2)}
                        value="2"
                        name="plan"
                        sx={{
                            color: 'rgba(106, 101, 253, 1)',
                            '&.Mui-checked': {
                                color: 'rgba(106, 101, 253, 1)',
                            },
                        }}
                        size="large" 
                    />
                    </div>
                    <div className={style.priceContainer}>
                        <div className={style.price}>{data.costMounth} ₽</div>
                        <div className={style.priceBeforeSale}>{data.costMounthbeforeSale} руб</div>
                    </div>
            </div>
                <div className={style.payContainer}>
                    <div className={style.upContainerPay}>
                        <div className={style.infoContainerPay}>
                            <div style={{fontSize: "20px", fontWeight: "600"}}>Оплата</div>
                            <div className={style.priceContainerPay}>
                                <div style={{fontSize: "16px", fontWeight: "500"}}>Сумма заказа</div>
                                <div style={{fontSize: "16px", fontWeight: "500"}}>{payData}₽</div>
                            </div>
                            <input
                            className={style.promo} 
                            type="number"
                            placeholder="Ввести промокод" />
                        </div>
                    </div>
                    <div className={style.downContainerPay}></div>
                        <div className={style.infoPayContainer}>
                            <div style={{fontSize: "20px", fontWeight: "600"}}>Итого</div>
                            <div style={{fontSize: "24px", fontWeight: "600"}}>{payData}₽</div>
                        </div>
                        <button className={style.btnPay}>Оплатить {payData}₽</button>
                </div>
            </div>) : (
                            <div className={style.rightContainer}>
                            <div className={style.courseContainer}
                            onClick={() => handleClick(2)}
                            style={{
                            outline: selected === 2 ? '2px solid #6A65FD' : '1px solid #6B65FD80',
                            cursor: 'pointer'
                            }}>
                                <div className={style.left}>
                                    <div style={{fontSize: "16px", fontWeight: "600"}}>Курс</div>
                                    <Radio
                        checked={selected === 2}
                        onChange={() => handleClick(2)}
                        value="2"
                        name="plan"
                        sx={{
                            color: 'rgba(106, 101, 253, 1)',
                            '&.Mui-checked': {
                                color: 'rgba(106, 101, 253, 1)',
                            },
                        }}
                        size="large" 
                    />
                                </div>
                                <div className={style.priceContainer}>
                                    <div className={style.price}>{data.costMounth} ₽</div>
                                    <div className={style.priceBeforeSale}>{data.costMounthbeforeSale} ₽</div>
                                </div>
                            </div>
                            <div className={style.payContainer}>
                                <div className={style.upContainerPay}>
                                    <div className={style.infoContainerPay}>
                                        <div style={{fontSize: "20px", fontWeight: "600"}}>Оплата</div>
                                        <div className={style.priceContainerPay}>
                                            <div style={{fontSize: "16px", fontWeight: "500"}}>Сумма заказа</div>
                                            <div style={{fontSize: "16px", fontWeight: "500"}}>{payData}₽</div>
                                        </div>
                                        <input
                                        className={style.promo} 
                                        type="number"
                                        placeholder="Ввести промокод" />
                                    </div>
                                </div>
                                <div className={style.downContainerPay}></div>
                                    <div className={style.infoPayContainer}>
                                        <div style={{fontSize: "20px", fontWeight: "600"}}>Итого</div>
                                        <div style={{fontSize: "24px", fontWeight: "600"}}>{payData}₽</div>
                                    </div>
                                    <button className={style.btnPay}>Оплатить {payData}₽</button>
                            </div>
                        </div>
            )}

        </div>
    )
}
}

export default Basket;