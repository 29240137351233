import style from "./NotificationMobilePage.module.css";
import axios from "axios";
import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import Loading from "../components/Load/Loading";

export default function NotificationMobilePage() {
  const [mobileTitle, setMobileTitle] = useOutletContext();
  const [loading, setLoading] = useState(true);
  setMobileTitle("Уведомления");

  const [notifications, setNotifications] = useState(null);

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await axios.get(
          "https://extremumcourse.ru/api/notifications",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
            },
          }
        );
        setNotifications(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    };

    fetchNotifications();
  }, []);
  if (loading) {
    return <div style={{marginTop: "-20%"}}><Loading/></div>;
  } else {
  return (
    <div className={style.main}>
      {notifications && notifications.length > 0 ? (
        <div className={style.container}>
          {notifications.map((notification, index) => (
            <div key={index} className={index === 0 ? style.notificationIndex0 : style.notification}>
              <div className={style.titleNotif}>{notification.title}</div>
              <div className={style.descriptionNotif}>
                {notification.description}
              </div>
              {notification.elements &&
                notification.elements.length > 0 &&
                notification.elements.map((element, i) =>
                  element.type === "button" ? (
                    <button
                      className={style.btnNotif}
                      key={i}
                      onClick={() =>
                        (window.location.href = element.redirect
                          ? element.redirect.url
                          : "#")
                      }
                    >
                      {element.value}
                    </button>
                  ) : null
                )}
            </div>
          ))}
        </div>
      ) : (
        <div className={style.noNotifications}>
          У вас нет уведомлений
        </div>
      )}
    </div>
  );
}
}
