import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import avatar from "../img/photo_5406613332124882751_m.jpg";
import telegram from "../img/telegram.png";

import style from "./Profile.module.css";
import API from "../services/API";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { Context } from "../App";

function Profile() {

	const navigate = useNavigate();
	const [mobileTitle, setMobileTitle] = useOutletContext();

	const [passwordMode, switchPasswordMode] = useState(false);
	const [editMode, switchEditMode] = useState(false)

	const [name, setName] = useState("");
	const [secondName, setSecondName] = useState("");
	const [city, setCity] = useState("");
	const [wantedUniv, setWantedUniv] = useState("");
	const [email, setEmail] = useState("");
	const [phoneNumber, setPhoneNumber] = useState("");

	const [oldPass, setOldPass] = useState("");
	const [newPass, setNewPass] = useState("");
	const [repeatPass, setRepeatPass] = useState("");

	const [tgLink, setTgLink] = useState("");

	const [isLoading, setIsLoading] = useState(true);
	const [imageIsLoading, setImageIsLoading] = useState(true);

	const context = useContext(Context);

	async function pickFile(onFilePicked){
		const inputElement = document.createElement('input');
		inputElement.style.display = 'none';
		inputElement.type = 'file';

		await inputElement.addEventListener('change', ()=> {
			if (inputElement.files) {
				onFilePicked(inputElement.files[0]);
			}
		});

		const teardown = () => {
			document.body.removeEventListener('focus', teardown, true);
			setTimeout(()=> {
				document.body.removeChild(inputElement);
			}, 1000);
		}

		document.body.addEventListener('focus', teardown, true);
		document.body.appendChild(inputElement);
		inputElement.click(); 
	}

	function inputFieldHandle(event, stateCtrl) {
		event.preventDefault();
		stateCtrl(event.target.value);
	}

	function enableEditMode() {
		switchEditMode(true)
	}

	function disableEditMode() {
		switchEditMode(false)
	}

	function closeModalWindow() {
		switchPasswordMode(false)
	}

	function openModalWindow() {
		switchPasswordMode(true)
	}

	async function saveChangesHandle(event) {
		event.preventDefault();
		let result = await API.patchProfile({
			first_name: name,
			last_name: secondName,
			city: city,
			university: wantedUniv,
			phone: phoneNumber,
		});
		switchEditMode(false);
	}

	async function changePassword() {
		let result = await API.changePassword(oldPass, newPass);
		switchPasswordMode(false);
	}

	async function changeImageHandle(event) {
		pickFile(async (file)=>{
			setImageIsLoading(true);
			let result = await API.changeAvatar(file);
			if (result.ok) {
				let fr = new FileReader();
				fr.onload = function(event) {
					context.setAvatar(event.target.result)
				}
				fr.readAsDataURL(file);
			} else {

			}
			setImageIsLoading(false);
		});
	}

	async function deleteAvatarHandler() {
		let result = await API.deleteAvatar();
	}

	function quitProfileHandle(event) {
		event.preventDefault();
		localStorage.removeItem("token");
		window.location.reload()
		navigate("../");
	}

	function redirectToTg() {
		window.open(tgLink, '_blank');
	}

	async function getMe() {
		const me = await API.getProfile();
		if (me != null) {
			setName(me.first_name ? me.first_name : name);
			setSecondName(me.last_name ? me.last_name : secondName);
			setCity(me.reflect.city ? me.reflect.city : '');
			setWantedUniv(me.reflect.university ? me.reflect.university : '');
			setEmail(me.login && me.login.email && me.login.email.email ? me.login.email.email : '');
			setPhoneNumber(me.contact.phone ? me.contact.phone : '')
			
			let result = await API.bindTelegram();
			if (result) setTgLink(result.telegram_url);

			result = await API.getAvatar();
			if (result) context.setAvatar(URL.createObjectURL(result));

			setImageIsLoading(false);

		}
	}

	useEffect(()=> {
		async function get() {
			await getMe();
			setIsLoading(false);
		}
		context.setTopBarTitle("Профиль");
		context.setMenuItem(4);
		get()
	}, [])

	if (window.innerWidth < 932) {
		if (editMode) return (
			<div className={style.mobileProfile}>
				<div className={style.mobileImage}>
					{
						imageIsLoading ? <div style={{
							borderRadius: '100%',
							height: '8rem',
							width: '8rem',
							backgroundColor: "rgba(244, 243, 255, 1)",
						}}><span class="loader"></span></div> :
						<img className={style.ava} alt="аватар" src={context.avatar} style={{
						borderRadius: '100%',
						height: '8rem',
						width: '8rem',
						objectFit: 'cover'
					}}></img>
					}
				<div className={style.mobileName}>
					{name + " " + secondName}
				</div>
				<div className={style.mobileEmail}>
					{email}
				</div>
				<div className={style.mobileLoadImageBtn}>
					Загрузить новую фотографию
				</div>
				<button className={style.mobileBindTgBtn} onClick={redirectToTg}>
					Привязать телеграмм
				</button>
				</div>
				<div className={style.mobileProfileData}>
					<div className={style.mobileEditString}>
						<label>Имя</label>
						<input value={name} onChange={(event)=>{inputFieldHandle(event, setName)}}></input>
					</div>
					<div className={style.mobileEditString}>
						<label>Фамилия</label>
						<input value={secondName} onChange={(event)=>{inputFieldHandle(event, setSecondName)}}></input>
					</div>
					<div className={style.mobileEditString}>
						<label>Желаемый ВУЗ</label>
						<input value={wantedUniv} onChange={(event)=>{inputFieldHandle(event, setWantedUniv)}}></input>
					</div>
					<div className={style.mobileEditString}>
						<label>Город</label>
						<input value={city} onChange={(event)=>{inputFieldHandle(event, setCity)}}></input>
					</div>
					<div className={style.flatLine}></div>
					<div>
						<label>Номер телефона</label>
						<div className={style.mobileString}>{phoneNumber}</div>
					</div>
				</div>
				<div className={style.mobileButtonsBlock}>
					<button className={style.mobileCommonBtn} onClick={saveChangesHandle}>Сохранить</button>
				</div>
			</div>
		)
		
		
		if (passwordMode) return (
			<div className={style.pswdScreen}>
				<div className={style.pswdTitle}>
					Смена пароля
				</div>
				<div className={style.mobilePswdString}>
					<div>Введите старый пароль</div>
					<input value={oldPass} onChange={(event) => {inputFieldHandle(event, setOldPass)}} type="password"></input>
				</div>
				<div className={style.mobilePswdString}>
					<div>Введите новый пароль</div>
					<input value={newPass} onChange={(event) => {inputFieldHandle(event, setNewPass)}} type="password"></input>
				</div>
				<div className={style.mobilePswdString}>
					<div>Повторите новый пароль</div>
					<input value={repeatPass} onChange={(event) => {inputFieldHandle(event, setRepeatPass)}} type="password"></input>
				</div>
				<div className={style.mobilePswdSaveBtnContaiiner}>
					<button onClick={()=>changePassword(oldPass, newPass)}className={style.mobilePswdSaveBtn}>Сохранить пароль</button>
				</div>
			</div>
		);

		return (
		<>
			<div className={style.mobileProfile}>
				<div className={style.mobileImage}>
					{
						imageIsLoading ? <div style={{
							borderRadius: '100%',
							height: '8rem',
							width: '8rem',
							backgroundColor: "rgba(244, 243, 255, 1)",
							margin: "auto",
							padding: "auto",
							display: "flex"
						}}>
								<span className={style.loader} style={{
									margin: "auto"
								}}></span>  
							</div>
					:
					<img alt="аватар" src={context.avatar} style={{
						borderRadius: '100%',
						height: '8rem',
						width: '8rem',
						objectFit: 'cover',
					}}></img>
					}
				<div className={style.mobileName}>
					{name + " " + secondName}
				</div>
				<div className={style.mobileEmail}>
					{email}
				</div>
				<div className={style.mobileLoadImageBtn} onClick={changeImageHandle}>
					Загрузить новую фотографию
				</div>
				<button className={style.mobileBindTgBtn} onClick={redirectToTg}>
					Привязать телеграмм
				</button>
				</div>
				<div className={style.mobileProfileData}>
					<div>
						<label>Желаемый ВУЗ</label>
						<div className={style.mobileString}>{wantedUniv}</div>
					</div>
					<div>
						<label>Город</label>
						<div className={style.mobileString}>{city}</div>
					</div>
					<div className={style.flatLine}></div>
					<div>
						<label>Номер телефона</label>
						<div className={style.mobileString}>{phoneNumber}</div>
					</div>
				</div>
				<div className={style.mobileButtonsBlock}>
					<button className={style.mobileCommonBtn} onClick={enableEditMode}>Редактировать</button>
					<button className={style.mobileCommonBtn} onClick={openModalWindow}>Сменить пароль</button>
					<button className={style.mobileCommonBtn} onClick={quitProfileHandle}>Выйти</button>
				</div>
			</div>
		</>
		)
	}

	return (
	<>	
		{passwordMode ? 
		<div className={style.modalWrapper}>
			<div className={style.modalWindow}>
				<div className={style.cross} onClick={closeModalWindow}>&times;</div>
				<div className={style.modalTitle}>
					Смена пароля
				</div>
				<div className={style.modalInputContainer}>
					<div className={style.labeledInput + " " + style.centerAlignedText}>
						<label>
							Введите старый пароль
						</label>
						<input type="password" onChange={(event) => {
							inputFieldHandle(event, setOldPass)
						}}></input>
					</div>
					<div className={style.labeledInput + " " + style.centerAlignedText}>
						<label>Введите новый пароль</label>
						<input type="password" onChange={(event) => {
							inputFieldHandle(event, setNewPass)
						}}></input>
					</div>
					<div className={style.labeledInput + " " + style.centerAlignedText}>
						<label>Повторите новый пароль</label>
						<input type="password" onChange={(event) => {
							inputFieldHandle(event, setRepeatPass)
						}}></input>
					</div>
				</div>
				<button className={style.editModeBtn} onClick={changePassword}>Сохранить пароль</button>
			</div>
		</div>
		: <></>}

		<div className={style.profile}>
			<div className={style.top}>
				{
					imageIsLoading ?
					<div style={{
						borderRadius: '100%',
						width: '120px',
						height: '120px',
						display: "flex",
						backgroundColor: "rgba(244, 243, 255, 1)",
					}}>
						<span className={style.loader} style={{
									margin: "auto"
								}}></span>  
					</div>
					: 
				<img alt="аватар" src={context.avatar} style={{
					borderRadius: '100%',
					width: '120px',
					height: '120px',
					objectFit: 'cover'
				}}></img>
				}
				<button className={style.loadNewImgBtn} onClick={changeImageHandle}>Загрузить новую фотографию</button>
				<button className={style.deleteBtn}onClick={deleteAvatarHandler}>Удалить</button>
				<div>Размер файла не может <br/> превышать 10 Мб</div>
			</div>
			<div className={style.main}>
				<div className={style.left}>
					<div className={style.leftSub}>
						<div className={style.labeledInput}>
							<label>Имя</label>
							{
								//<div className={style.string}>{name}</div>
								editMode ? <input value={name} onChange={(event)=>{inputFieldHandle(event, setName)}}></input>
								 		 : <div className={style.string}>{name}</div>
							}
						</div>
						<div className={style.labeledInput}>
							<label>Фамилия</label>
							{
								//<div className={style.string}>{secondName}</div>
								editMode ? <input value={secondName} onChange={(event)=>{inputFieldHandle(event, setSecondName)}}></input>
										 : <div className={style.string}>{secondName}</div>
							}
						</div>
						{
							editMode ? <button className={style.editModeBtn  + " " + style.saveBtn} onClick={saveChangesHandle}>Сохранить</button>
									 : <button className={style.commonBtn} onClick={enableEditMode}>Редактировать</button>
						}
					</div>
					<div className={style.leftSub}>
						<div className={style.labeledInput}>
							<label>Город</label>
							{
								editMode ? <input value={city} onChange={(event)=>{inputFieldHandle(event, setCity)}}></input>
										 : <div className={style.string}>{city}</div>
							}
						</div>
						<div className={style.labeledInput}>
							<label>Желаемый вуз</label>
							{
								editMode ? <textarea style={{
												resize: 'none',
												overflowWrap: 'break-word',
												overflow: "hidden"
											}} value={wantedUniv}
											onChange={(event)=>{inputFieldHandle(event, setWantedUniv)}}></textarea>
										 : <div className={style.text}>{wantedUniv}</div>
							}
						</div>
						<button className={style.commonBtn} onClick={quitProfileHandle}>Выйти из аккаунта</button>
					</div>
				</div>
				<div className={style.line}></div>
				<div className={style.right}>
					<div className={style.labeledInput}>
						<label>E-mail</label>
						{
							editMode ? <input value={email} onChange={(event)=>{inputFieldHandle(event, setEmail)}}></input>
									 : <div className={style.string}>{email}</div>
						}
					</div>
					<div className={style.labeledInput}>
						<label>Номер телефона</label>
						{
							editMode ? <input value={phoneNumber} onChange={(event)=>{inputFieldHandle(event, setPhoneNumber)}}></input>
									 : <div className={style.string}>{phoneNumber}</div>
						}
					</div>
					<div className={style.labeledInput}>
						<label>Телеграм</label>
						<div className={style.editModeBtn + " " + style.tgBtn} onClick={redirectToTg}>
							<img src={telegram}></img>
							<div>Привязать телеграм</div>
						</div>
					</div>
					{
						<button className={style.commonBtn + " " + style.passBtn} onClick={openModalWindow}>Сменить пароль</button>
					}
				</div>
			</div>
		</div>
	</>
	)
}

export default Profile;