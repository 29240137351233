// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CourseList_courseList__aQO0C {
        width: 77vw;
        max-width: 1920px;
        box-sizing: border-box;
        background-color: #fff;
        border-radius: 10px;
        height: 18vh;
        padding: 5vh 5vh;
        text-align: center;
        margin-right: 2vw;
}

@media (max-width: 767.98px) {

        .CourseList_courseList__aQO0C {
              width: 90vw;  
              margin: auto;
              margin-top: 5%;
        }
        .CourseList_title__EysGt {
                font-size: 22px !important;
                width: 100%;
        }
        .CourseList_title2__koHJ8 {
                font-size: 18px !important;
                width: 100%;
                margin-top: 5px;
        }
}
/* 100% зум */
@media (min-width: 1746px) and (max-width: 1920px) {

}
/* 110% зум */
@media (min-width: 1537px) and (max-width: 1745px) { 

}
/* 125% зум */
@media (min-width: 1281px) and (max-width: 1536px) { 

}
/* 150% зум */
@media (min-width: 1098px) and (max-width: 1280px) {
.CourseList_courseList__aQO0C {
    width: 72vw;
}
}
/* 175% зум */
@media (min-width: 961px) and (max-width: 1097px) {  
        .CourseList_courseList__aQO0C {
                width: 70vw;
            }
}
/* 200% зум */
@media (max-width: 960px) {
}`, "",{"version":3,"sources":["webpack://./src/components/CoursesList/CourseList.module.css"],"names":[],"mappings":"AAAA;QACQ,WAAW;QACX,iBAAiB;QACjB,sBAAsB;QACtB,sBAAsB;QACtB,mBAAmB;QACnB,YAAY;QACZ,gBAAgB;QAChB,kBAAkB;QAClB,iBAAiB;AACzB;;AAEA;;QAEQ;cACM,WAAW;cACX,YAAY;cACZ,cAAc;QACpB;QACA;gBACQ,0BAA0B;gBAC1B,WAAW;QACnB;QACA;gBACQ,0BAA0B;gBAC1B,WAAW;gBACX,eAAe;QACvB;AACR;AACA,aAAa;AACb;;AAEA;AACA,aAAa;AACb;;AAEA;AACA,aAAa;AACb;;AAEA;AACA,aAAa;AACb;AACA;IACI,WAAW;AACf;AACA;AACA,aAAa;AACb;QACQ;gBACQ,WAAW;YACf;AACZ;AACA,aAAa;AACb;AACA","sourcesContent":[".courseList {\n        width: 77vw;\n        max-width: 1920px;\n        box-sizing: border-box;\n        background-color: #fff;\n        border-radius: 10px;\n        height: 18vh;\n        padding: 5vh 5vh;\n        text-align: center;\n        margin-right: 2vw;\n}\n\n@media (max-width: 767.98px) {\n\n        .courseList {\n              width: 90vw;  \n              margin: auto;\n              margin-top: 5%;\n        }\n        .title {\n                font-size: 22px !important;\n                width: 100%;\n        }\n        .title2 {\n                font-size: 18px !important;\n                width: 100%;\n                margin-top: 5px;\n        }\n}\n/* 100% зум */\n@media (min-width: 1746px) and (max-width: 1920px) {\n\n}\n/* 110% зум */\n@media (min-width: 1537px) and (max-width: 1745px) { \n\n}\n/* 125% зум */\n@media (min-width: 1281px) and (max-width: 1536px) { \n\n}\n/* 150% зум */\n@media (min-width: 1098px) and (max-width: 1280px) {\n.courseList {\n    width: 72vw;\n}\n}\n/* 175% зум */\n@media (min-width: 961px) and (max-width: 1097px) {  \n        .courseList {\n                width: 70vw;\n            }\n}\n/* 200% зум */\n@media (max-width: 960px) {\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"courseList": `CourseList_courseList__aQO0C`,
	"title": `CourseList_title__EysGt`,
	"title2": `CourseList_title2__koHJ8`
};
export default ___CSS_LOADER_EXPORT___;
