function InviteIcon(props) {
    return (
        <svg width="34" height="36" viewBox="0 0 34 36" fill="none" xmlns="https://www.w3.org/2000/svg">
            <path
                d="M1.77734 30.6663C1.77734 28.7803 2.52655 26.9716 3.86014 25.638C5.19373 24.3044 7.00247 23.5552 8.88845 23.5552H23.1107C24.9967 23.5552 26.8054 24.3044 28.139 25.638C29.4726 26.9716 30.2218 28.7803 30.2218 30.6663C30.2218 31.6093 29.8472 32.5136 29.1804 33.1804C28.5136 33.8472 27.6092 34.2218 26.6662 34.2218H5.3329C4.38991 34.2218 3.48554 33.8472 2.81874 33.1804C2.15195 32.5136 1.77734 31.6093 1.77734 30.6663Z"
                stroke={props.mainColor}
                strokeWidth="2"
                strokeLinejoin="round"/>
            <path
                d="M15.9993 16.444C18.9449 16.444 21.3327 14.0562 21.3327 11.1107C21.3327 8.16516 18.9449 5.77734 15.9993 5.77734C13.0538 5.77734 10.666 8.16516 10.666 11.1107C10.666 14.0562 13.0538 16.444 15.9993 16.444Z"
                stroke={props.mainColor}
                strokeWidth="2"/>
            <path
                d="M28 0V12"
                stroke={props.mainColor}
                strokeWidth="2"/>
            <path
                d="M22 6L34 6"
                stroke={props.mainColor}
                strokeWidth="2"/>
        </svg>
    )
}

export default InviteIcon;