function ProfileIcon(props) {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="https://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_413_1129)">
                <path
                    d="M1.77783 26.6665C1.77783 24.7805 2.52704 22.9718 3.86063 21.6382C5.19422 20.3046 7.00296 19.5554 8.88894 19.5554H23.1112C24.9971 19.5554 26.8059 20.3046 28.1395 21.6382C29.4731 22.9718 30.2223 24.7805 30.2223 26.6665C30.2223 27.6095 29.8477 28.5139 29.1809 29.1807C28.5141 29.8475 27.6097 30.2221 26.6667 30.2221H5.33339C4.3904 30.2221 3.48603 29.8475 2.81923 29.1807C2.15243 28.5139 1.77783 27.6095 1.77783 26.6665Z"
                    stroke={props.mainColor}
                    strokeWidth="2"
                    strokeLinejoin="round"
                />
                <path
                    d="M15.9998 12.4443C18.9454 12.4443 21.3332 10.0564 21.3332 7.11092C21.3332 4.1654 18.9454 1.77759 15.9998 1.77759C13.0543 1.77759 10.6665 4.1654 10.6665 7.11092C10.6665 10.0564 13.0543 12.4443 15.9998 12.4443Z"
                    stroke={props.mainColor}
                    strokeWidth="2"
                />
            </g>
            <defs>
                <clipPath id="clip0_413_1129">
                    <rect width="32" height="32" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}

export default ProfileIcon;