function StudentHatIcon(props) {
    return (
        <svg width="36" height="33" viewBox="0 0 36 33" fill="none" xmlns="https://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_413_1123)">
                <path
                    d="M26.75 24.3937L18 29.3156L9.25 24.3937V19.1936L6.75 17.8047V25.8558L18 32.1839L29.25 25.8558V17.8047L26.75 19.1936V24.3937Z"
                    fill={props.mainColor}
                    stroke="none"
                />
                <path 
                    d="M18 0.591797L0.5 9.66586V11.8326L18 21.5545L33 13.2215V20.1248H35.5V9.66586L18 0.591797ZM30.5 11.7505L28 13.1393L18 18.6952L8 13.1393L5.5 11.7505L3.76727 10.7878L18 3.40789L32.2327 10.7878L30.5 11.7505Z"
                    fill={props.mainColor}
                    stroke="none"
                />
            </g>
            <defs>
                <clipPath id="clip0_413_1123">
                    <rect width="36" height="33" fill="none"/>
                </clipPath>
            </defs>
        </svg>
    );
}

export default StudentHatIcon;