import { GrNotification } from "react-icons/gr";

function Notification() {
    return (
        <>
        <GrNotification style={{fontSize:"250%"}}/>
        </>
    )
}

export default Notification