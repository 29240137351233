import { useEffect, useState } from "react";

import API from "../services/API";
import { Context } from "../App";

import star_1 from "../img/star_1.png";
import star_2 from "../img/star_2.png";
import star_3 from "../img/star_3.png";
import clone from "../img/clone.png";

import style from "./Invite.module.css";
import { useContext } from "react";

function Invite() {
    const [WIDTH, SET_WIDTH] = useState(window.innerWidth)
    window.addEventListener('resize', (event)=>{
        SET_WIDTH(window.innerWidth)
    }, false);

    const context = useContext(Context);

    const [bonus, setBonus] = useState(true);
    const [link, setLink] = useState("extremum.com/");

    const [phone, setPhone] = useState("");
    const [card, setCard] = useState("");
    const [saving, setSaving] = useState(false);
    const [success, setSuccess] = useState();

    function copyLink() {
        const textArea = document.createElement('textarea');
        textArea.value = link;
        document.body.appendChild(textArea);
        textArea.select();
        try {
            document.execCommand('copy');
            alert('Ccылка скопиована в буфер обмена');
        } catch (error){
            console.error("Ошибка копирования")
        } finally {
            document.body.removeChild(textArea);
        }
    }

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    async function saveHandler() {
        setSaving(true);
        let result;
        if (bonus) {
            result = await API.patchProfile({card: card});
        } else {
            result = await API.patchProfile({phone: phone});
        }
        if (result) {
            setSuccess(true); 
        } else {
            setSuccess(false);
        }
        await sleep(2000)
        setSaving(false);
    }

    function phoneHandler(event) {
        setPhone(event.target.value);
    }

    function cardHandler(event) {
        setCard(event.target.value);
    }

    useEffect(() => {
        async function getProfile() {
            let result = await API.getProfile();
            setLink(link + result.referral);
            setPhone(result.contact.phone ? result.contact.phone : "");
            setCard(result.payment.card ? result.payment.card : "");
        }
        context.setTopBarTitle("Пригласить друга")
        getProfile();
    }, [])

    return (
        <>
        <div className={style.mainLayer}>
            <div className={style.title}>Помогайте друзьям и получайте</div>
            <div className={style.violetTitle}>бонусы от Extremum</div>
            <div className={style.article}>
                У вас наверняка есть друзья, который хотят лучше подготовиться к экаменам, понять школьные предметы и поступить в ВУЗ мечты.
            </div>
            <h3>В ваших силах помочь им это сделать.</h3>
            <h3>Пригласите их пройти обучение в Extremum !</h3>
            <div className={style.howItWorks}>
                <div className={style.subTitle}> Как это работает: </div>
                <div className={style.actList}>
                    <div className={style.actCntr}>
                        <img alt="1" src={star_1}/>
                        <div className={style.actUnit}> Делитесь персональной ссылкой</div>
                    </div>
                    <div className={style.actCntr}>
                        <img alt="2" src={star_2}/>
                        <div className={style.actUnit}> Друзья переходят по ссылке и покупают курс со скидкой</div>
                    </div>
                    <div className={style.actCntr}>
                        <img alt="3" src={star_3}/>
                        <div className={style.actUnit}> Вы получаете бонус для оплаты курса</div>
                    </div> 
                </div>
                <div className={style.subTitle}> Получайте 500₽ с каждой покупки друга!</div>
            </div>
            <div className={style.note}>Важное уточнение: ученику необходимо <span style={{color: "#6A65FD"}}>отучиться 2 недели</span> на любом из курсов, чтобы начислялись бонусы</div>
            <div className={style.step}>
                <div className={style.stepTitle}>Шаг 1: Делитесь ссылкой</div>
                {
                    window.innerWidth > 932
                    ?
                        <div className={style.reflinkContainer}>    
                            <input className={style.reflink} value={link} readOnly></input>
                            <button onClick={copyLink}>
                                <p>Копировать ссылку</p><img alt="Копировать" src={clone}></img>
                            </button>
                        </div>
                    :
                        <div className={style.reflinkContainer}>
                            <input value={link} readOnly></input>
                            <img alt="Копировапть" src={clone} onClick={copyLink}></img>
                        </div>
                }
                <div className={style.stepNote}>Поделившись ссылкой вы соглашаетесь с условиями изложенными выше</div>
            </div>
            <div className={style.step}>
                <div className={style.stepTitle}>Шаг 2: Выберите удобный для себя счет на начисление бонусов</div>
                <div className={style.btnCntr}>
                    <button className={!bonus ? style.btn : style.btn_dis} onClick={()=>{setBonus(true)}}>Банковская карта</button>
                    <button className={bonus ? style.btn : style.btn_dis} onClick={()=>{setBonus(false)}}>Номер телефона</button>
                </div>
            </div>
            {
                bonus ?
                <div className={style.step}>
                    <p className={style.stepTitle}>Шаг 3: Укажите номер банковской карты для начисления бонусов</p>
                    {
                        window.innerWidth < 932 ?
                        <>
                        <div className={style.billContainer}>
                            <input
                                placeholder="XXXX-XXXX-XXXX-XXXX"
                                value={card}
                                onChange={cardHandler}
                                ></input>
                        </div>
                        <div className={style.btnCntr}>
                        <button className={style.btn} onClick={saveHandler}>
                        {saving ? <span className={style.loader}></span> : "Сохранить"}
                        </button>
                        </div>
                        </> 
                        :
                        <div style={{
                            position: "relative",
                            height: "4rem"
                        }}>
                            <input
                                className={style.reflink}
                                placeholder="XXXX-XXXX-XXXX-XXXX"
                                value={card}
                                onChange={cardHandler}
                            ></input>
                            <span className={style.copylink} onClick={saveHandler}>
                            {saving ? <span className={style.loader}></span> : "Сохранить"}
                        </span>
                        </div>
                    }
                </div> :
                <div className={style.step}>
                    <div className={style.stepTitle}>Шаг 3: Укажите номер телефона для начисления бонусов</div>
                    {
                        window.innerWidth < 932 ?
                        <>
                        <div className={style.billContainer}>
                            <input
                                placeholder="+7 (999) 999 99 99"
                                value={phone}
                                onChange={phoneHandler}
                                ></input>
                        </div>
                        <div className={style.btnCntr}>
                        <button className={style.btn} onClick={saveHandler}>
                        {saving ? <span className={style.loader}></span> : "Сохранить"}
                        </button>
                        </div>
                        </> 
                        :
                        <div style={{
                            position: "relative",
                            height: "4rem"
                        }}>
                            <input
                                className={style.reflink}
                                placeholder="+7 (999) 999 99 99"
                                value={phone}
                                onChange={phoneHandler}
                            ></input>
                            <span className={style.copylink} onClick={saveHandler}>
                            {saving ? <span className={style.loader}></span> : "Сохранить"}
                        </span>
                        </div>
                    }
                </div>
            }
        </div>
        </>
    )
}

export default Invite;