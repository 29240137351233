import style from "./ChatMobile.module.css"
import send from "../../img/send.png"
import clip from "../../img/clip.png"
import { useOutletContext } from "react-router-dom";
import { Context } from "../../App";
import { useContext, useEffect } from "react";

export default function ChatMobile() {
    const context = useContext(Context)
    useEffect(()=>{
        context.setTopBarTitle("Вопрос куратору")
    }, [])
    return (
        <div className={style.main}>
            <div className={style.chatContainer}></div>
            <div className={style.footChat}>
                <textarea className={style.inputText}  type="text" placeholder="Опишите свой вопрос..." />
                <div className={style.btnContainer}>
                    <div className={style.clip}><img src={clip} alt="" /></div>
                    <div className={style.send}><img src={send} alt="" /></div>
                </div>
            </div>
        </div>
    )
}