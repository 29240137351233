//import CalendarIcon from "../components/Icons/CalendarIcon";
import OpenBookIcon from "../components/Icons/OpenBookIcon";
import StudentHatIcon from "../components/Icons/StudentHatIcon";
import Tasks from "../components/Tasks/Tasks";

import styles from "./PlayGround.module.css"; 

function PlayGround() {
    return (
        <div>
            <div style={
                {
                    height: "500px",
                    width: "500px",
                }
            }>
                <Tasks/>
            </div>
        </div>
    )
}

export default PlayGround;