// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Wix+Madefor+Display:wght@400..800&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Menu_menuBar__7io9L {
    display: flex;
    flex-direction: column;
    margin-top: 20%;
}

.Menu_mobileMenuBar__4vMzz {
    
}`, "",{"version":3,"sources":["webpack://./src/components/Menu/Menu.module.css"],"names":[],"mappings":"AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,eAAe;AACnB;;AAEA;;AAEA","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Wix+Madefor+Display:wght@400..800&display=swap');\n\n.menuBar {\n    display: flex;\n    flex-direction: column;\n    margin-top: 20%;\n}\n\n.mobileMenuBar {\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menuBar": `Menu_menuBar__7io9L`,
	"mobileMenuBar": `Menu_mobileMenuBar__4vMzz`
};
export default ___CSS_LOADER_EXPORT___;
