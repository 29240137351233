import React from 'react';
import style from './Loading.module.css';
import { useEffect, useState } from 'react';

const Loading = () => {
    const [width, setWidth] = useState(window.innerWidth);
return (
<div className={width > 768 ? style.wrapper : style.wrapper2}>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
</div>
    );
};

export default Loading;
