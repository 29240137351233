import style from "./PlansMobile.module.css";
import React, { useEffect, useState } from "react";
import img from "../img/course.png";
import lock from "../img/lock.png";
import tick1 from "../img/tick1.png";
import tick2 from "../img/tick2.png";
import { useNavigate } from "react-router-dom";
import { PATHS } from "../services/Consts";

function PlansMobile({ courseData, getPlanData, courseImg }) {
  const [selectedCourse, setSelectedCourse] = useState(courseData);
  const [img, setImg] = useState();

  const navigate = useNavigate()
    const getPlanContainerClass = (plans) => {
    if (plans.length === 1) {
        return style.singlePlanContainer;
    } else if (plans.length === 2) {
        return style.twoPlansContainer;
    } else {
        return style.threePlansContainer;
    }
};
function goToChat() {
    navigate(`../${PATHS.chat}`, { replace: false });
}


    function buyCourseDirect(planId) {
    const plan = selectedCourse.plans.find(p => p.planId === planId);
    if (plan) {
        getPlanData({
            plan,
            courseTitle: selectedCourse.title,
            courseImage: courseImg
        });
        navigate(`../${PATHS.basket}`, { replace: false });
    }
}

const getPlanClass = (title) => {
    switch (title) {
        case "Стандарт":
            return style.planStandard;
        case "Премиум":
            return style.planPremium;
        case "Самоучка":
            return style.planSelfLearner;
        default:
            return "";
    }
};

  return (
    <div className={style.mainContainer}>
       <div className={style.header}>
                            <div className={style.imgContainer}><img className={style.imgModal} src={courseImg} alt="img" /></div>
                            <div className={style.courseName}>{selectedCourse.title}</div>
                            <div className={style.courseDescriptionModal}>{selectedCourse.description}</div>
                            <button onClick={() => (goToChat())}className={style.btnAnswer}>Задать вопрос</button>
                        </div>
                        <div className={style.body}>
                            <div className={`${style.planContainer} ${getPlanContainerClass(selectedCourse.plans)}`}>

                            {selectedCourse.plans.map((plan, index) => (
    <div key={index} className={`${style.plan} ${getPlanClass(plan.title)}`}>
        {plan.title === "Самоучка" ? (
            <div 
                className={
                    selectedCourse.plans.length < 3 
                        ? `${style.planInfoConntainerSelf} ${style.flexColumn}` 
                        : style.planInfoConntainerSelf
                }
            >
                <div 
                    className={
                        selectedCourse.plans.length < 3 
                            ? ` ${style.dashedBorder}` 
                            : style.planSelfLearnerHeader
                    }
                >
                    <div className={style.planTitleSelf}>{plan.title}</div>
                    <div 
                        className={
                            selectedCourse.plans.length < 3 
                                ? `${style.planPriceContainerSelf} ${style.flexColumnReverse} ${style.marginTopBottom}` 
                                : style.planPriceContainerSelf
                        }
                    >
                        <span className={style.planPriceSelf}>{plan.costPerBlock} ₽</span>
                        {plan.lastCostPerBlock && (
                            <span className={style.planOldPriceSelf}>{plan.lastCostPerBlock} ₽</span>
                        )}
                    </div>
                </div>
                <div 
                    className={
                        selectedCourse.plans.length < 3 
                            ? `${style.planInfoContainerSelf} ${style.flexColumn}` 
                            : style.planInfoContainerSelf
                    }
                >
                    <div 
                        className={
                            selectedCourse.plans.length < 3 
                                ? `${style.planDescriptionItemSelf} ${style.flexRow} ${style.marginTop}` 
                                : style.planDescriptionItemSelf
                        }
                    >
                        <img src={tick2} alt="" />
                        <div>{plan.descriptionItems[0]}</div>
                    </div>
                    <button 
                        onClick={() => buyCourseDirect(plan.planId)} 
                        className={
                            selectedCourse.plans.length < 3 
                                ? `${style.planButtonSelf} ${style.fullWidth}` 
                                : style.planButtonSelf
                        }
                    >
                        Начать
                    </button>
                </div>
            </div>
        ) : (
                                            <>
                                                <div className={style.planHeader}>
                                                    <div className={style.planHeaderContainer}>
                                                        <div className={style.planTitle}>{plan.title}</div>
                                                        <div className={style.freePlaces}>Осталось мест: <span className={style.num}>{plan.freePlaces}</span></div>
                                                    </div>
                                                    <div className={style.planInfoContainer}>
                                                        <div className={style.planPriceContainer}>
                                                            <span className={style.planPrice}>{plan.costPerBlock} ₽<span className={style.titleSale}>/месяц</span></span>
                                                            {plan.lastCostPerBlock && (
                                                                <span className={style.planOldPrice}>{plan.lastCostPerBlock} ₽</span>
                                                            )}
                                                            <span className={style.planPriceCourse}>{plan.cost} ₽<span className={style.titleSale}>/курс</span></span>
                                                            {plan.lastCostPerBlock && (
                                                                <span className={style.planOldPriceCourse}>{plan.lastCost} ₽</span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={style.planDescription}>
                                                    {plan.descriptionItems.map((item, idx) => (
                                                        <div key={idx} className={style.planDescriptionItem}>
                                                            <img src={plan.title === "Стандарт" ? tick1 : tick2} alt="" />
                                                            <div>{item}</div>
                                                        </div>
                                                    ))}
                                                </div>
                                                <button onClick={() => buyCourseDirect(plan.planId)} className={style.planButton}>Начать</button>
                                            </>
                                            
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
  );
}


export default PlansMobile;
