import { IoMdCheckmark } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";

import style from "./LessonCard.module.css";

function LessonCard(props) {
    return (
        <div className={style.main} onClick={props.onClick}>
            {props.lesson.passed
             ? 
                <div className={style.leftCompleted}>
                <IoMdCheckmark style={{
                    position: "absolute",
                    top: "45%",
                    left: "30%",
                    fontSize: "175%",
                    color: "white",
                }}/>
                </div>
             :
                <div className={style.leftUncompleted}>
                    <IoIosArrowForward style={{
                        position: "absolute",
                        top: "45%",
                        left: "30%",
                        fontSize: "175%",
                        color: "black",
                    }}/>
                </div>
            }
            <div className={style.right} >
                <div className={style.lessonNumber}>
                    Урок {props.number}
                </div>
                <div className={style.title}>
                    {props.lesson.title}
                </div>
                <div className={style.progress}>
                    {
                        props.lesson.totalTasks === props.lesson.completedTasks
                        ?
                        <div className={style.progressText}>
                            Все задания выполнены
                        </div>
                        :
                        <div className={style.progressText}>
                            {props.lesson.completedTasks + "/" + props.lesson.totalTasks + " заданий"}
                        </div>
                    }
                    <div className={style.progressBar}>
                            <div className={style.completedBar} style={{
                                width: props.lesson.completedTasks / props.lesson.totalTasks * 100 + "%",}}>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LessonCard;