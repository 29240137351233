import React, { useState, useEffect } from "react";
import styles from "./TimeTableMobile.module.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { PATHS } from "../services/Consts";

const formatDateTime = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};
function Time(timeStr) {
  return timeStr.substring(0, 5);
}

function findLessonsByDate(day, month, lessons) {
  const result = [];

  lessons.forEach(lesson => {
      const lessonStartDate = new Date(lesson.lessonStartAt);
      const lessonDeadlineDate = new Date(lesson.lessonDeadlineAt);

      if (lessonStartDate.getDate() === day && (lessonStartDate.getMonth() + 1) === month) {
          result.push({
              lessonName: lesson.lessonName,
              courseName: lesson.courseName,
              typeInfo: 'lesson',
              time: lessonStartDate.toTimeString().split(' ')[0]
          });
      }

      if (lessonDeadlineDate.getDate() === day && (lessonDeadlineDate.getMonth() + 1) === month) {
          result.push({
              lessonName: lesson.lessonName,
              courseName: lesson.courseName,
              typeInfo: 'homework',
              time: lessonDeadlineDate.toTimeString().split(' ')[0]
          });
      }
  });

  return(result);
}
const TimeTableMobile = () => {
  const today = new Date();
  const [currentMonth, setCurrentMonth] = useState(today.getMonth());
  const [currentYear, setCurrentYear] = useState(today.getFullYear());
  const [lessonInfo, setLessonInfo] = useState(null);
  const [todayLesson, setTodayLesson] = useState(null)
  const [allLessons, setAllLessons] = useState(null)
  const [selectedDay, setSelectedDays] = useState(null);
  const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();
  const firstDayIndex = new Date(currentYear, currentMonth, 1).getDay();
  const daysArray = [...Array(daysInMonth).keys()].map((day) => day + 1);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchLessons = async () => {
      const startDate = new Date(2024, currentMonth, 1, 0, 0, 0); 
      const endDate = new Date(2024, currentMonth + 1, 0, 23, 59, 59); 

      const startAt = formatDateTime(startDate);
      const endAt = formatDateTime(endDate);
      
      try {
        const response = await axios.get('https://extremumcourse.ru/api/notifications/make-in-time', {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
          params: { startAt, endAt }
        });
        setAllLessons(response.data); 
        setTodayLesson(findLessonsByDate(today.getDate(), currentMonth + 1, response.data));
      } catch (error) {
        console.error('Ошибка при получении данных:', error);
      }
    };

    fetchLessons();
  }, [currentMonth]);

  const handlePrevMonth = () => {
    setSelectedDays(null)
    setLessonInfo(null)
    setTodayLesson(null)
    if (currentMonth === 0) {
      setCurrentMonth(11);
      setCurrentYear(currentYear - 1);
    } else {
      setCurrentMonth(currentMonth - 1);
    }
  };
  const handleDayClick =(day, month, lessons) => {
    setSelectedDays(day)
    setLessonInfo(null)
    setTodayLesson(null)
    const result = [];
    lessons.forEach(lesson => {
        const lessonStartDate = new Date(lesson.lessonStartAt);
        const lessonDeadlineDate = new Date(lesson.lessonDeadlineAt);

        if (lessonStartDate.getDate() === day && (lessonStartDate.getMonth() + 1) === month) {
            result.push({
                lessonName: lesson.lessonName,
                courseName: lesson.courseName,
                typeInfo: 'lesson',
                time: lessonStartDate.toTimeString().split(' ')[0]
            });
        }

        if (lessonDeadlineDate.getDate() === day && (lessonDeadlineDate.getMonth() + 1) === month) {
            result.push({
                lessonName: lesson.lessonName,
                courseName: lesson.courseName,
                typeInfo: 'homework',
                time: lessonDeadlineDate.toTimeString().split(' ')[0]
            });
        }
    });

    setLessonInfo(result);
  }
  const handleNextMonth = () => {
    setSelectedDays(null)
    setLessonInfo(null)
    setTodayLesson(null)
    if (currentMonth === 11) {
      setCurrentMonth(0);
      setCurrentYear(currentYear + 1);
    } else {
      setCurrentMonth(currentMonth + 1);
    }
  };

  const monthNames = [
    "Январь",
    "Февраль",
    "Март",
    "Апрель",
    "Май",
    "Июнь",
    "Июль",
    "Август",
    "Сентябрь",
    "Октябрь",
    "Ноябрь",
    "Декабрь",
  ];

  return (
    <div className={styles.calendar}>
      <div className={styles.header}>
        <button onClick={handlePrevMonth} className={styles.navButton}>
          {"<"}
        </button>
        <div className={styles.month}>{monthNames[currentMonth]}</div>
        <button onClick={handleNextMonth} className={styles.navButton}>
          {">"}
        </button>
      </div>
      <div className={styles.weekDays}>
        {["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"].map((day) => (
          <div key={day} className={styles.weekDay}>
            {day}
          </div>
        ))}
      </div>
      <div className={styles.days}>
        {[...Array(firstDayIndex === 0 ? 6 : firstDayIndex - 1).keys()].map(
          (_, index) => (
            <div key={index} className={styles.emptyDay}></div>
          )
        )}
        {daysArray.map((day) => (
          <div
            onClick={() => {handleDayClick(day, currentMonth + 1, allLessons)}}
            key={day}
            className={
              day === selectedDay && day !== today.getDate() ? styles.select : styles.day &&
              day === today.getDate() &&
              currentMonth === today.getMonth() &&
              currentYear === today.getFullYear()
                ? styles.selectedDay
                : styles.day
            }>
            {day}
          </div>
        ))}
      </div>
      {(todayLesson && !lessonInfo) && (
        todayLesson.map((lesson, index) => (
          <div key={index} className={styles.lesson} onClick={() => {navigate("../" + PATHS.myLearning)}}>
            <div className={styles.up}>
              <div className={styles.lessonName}>{lesson.lessonName}</div>
              <div className={styles.time}>{Time(lesson.time)}</div>
            </div>
            <div className={styles.down}>
              <div className={styles.courseName}>{lesson.courseName}</div>
              {lesson.typeInfo === "homework" ? <div className={styles.typeInfo}>Д/З</div> : <div className={styles.typeInfo}>Урок</div>}
              
            </div>
          </div>
        ))
      ) }
      {lessonInfo && (
        lessonInfo.map((lesson, index) => (
            <div key={index} className={styles.lesson} onClick={() => {navigate("../" + PATHS.myLearning)}}>
            <div className={styles.up}>
              <div className={styles.lessonName}>{lesson.lessonName}</div>
              <div className={styles.time}>{Time(lesson.time)}</div>
            </div>
            <div className={styles.down}>
              <div className={styles.courseName}>{lesson.courseName}</div>
              {lesson.typeInfo === "homework" ? <div className={styles.typeInfo}>Д/З</div> : <div className={styles.typeInfo}>Урок</div>}
              
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default TimeTableMobile;
