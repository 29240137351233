function MessageIcon(props) {
    return (
        <svg width="32" height="29" viewBox="0 0 32 29" fill="none" xmlns="https://www.w3.org/2000/svg" transform="translate(0,5)">
            <g clipPath="url(#clip0_413_1126)">
                <path d="M29.75 0.125H2.25004C1.75275 0.125 1.27584 0.322544 0.924211 0.674175C0.57258 1.02581 0.375036 1.50272 0.375036 2V27C0.372815 27.3575 0.473796 27.7081 0.665863 28.0096C0.85793 28.3111 1.13292 28.5509 1.45785 28.7C1.70625 28.8146 1.97646 28.8743 2.25004 28.875C2.6903 28.8746 3.11612 28.7179 3.4516 28.4328L3.46097 28.4234L8.48285 24.0219C8.59691 23.9258 8.74155 23.8737 8.89066 23.875H29.75C30.2473 23.875 30.7242 23.6775 31.0759 23.3258C31.4275 22.9742 31.625 22.4973 31.625 22V2C31.625 1.50272 31.4275 1.02581 31.0759 0.674175C30.7242 0.322544 30.2473 0.125 29.75 0.125ZM30.375 22C30.375 22.1658 30.3092 22.3247 30.192 22.4419C30.0748 22.5592 29.9158 22.625 29.75 22.625H8.89066C8.44474 22.6263 8.01362 22.7851 7.67347 23.0734L2.64847 27.4797C2.55721 27.5552 2.44638 27.6032 2.32887 27.6182C2.21137 27.6331 2.09204 27.6144 1.98478 27.5641C1.87753 27.5138 1.78677 27.4341 1.72308 27.3342C1.65939 27.2344 1.62538 27.1185 1.62504 27V2C1.62504 1.83424 1.69088 1.67527 1.80809 1.55806C1.9253 1.44085 2.08428 1.375 2.25004 1.375H29.75C29.9158 1.375 30.0748 1.44085 30.192 1.55806C30.3092 1.67527 30.375 1.83424 30.375 2V22Z" fill={props.mainColor}/>
                <path d="M29.2 1H2.80003C2.32264 1 1.86481 1.18552 1.52724 1.51575C1.18968 1.84597 1.00003 2.29386 1.00003 2.76087V26.2391C0.997903 26.5749 1.09484 26.9041 1.27923 27.1873C1.46361 27.4705 1.72761 27.6956 2.03953 27.8357C2.278 27.9433 2.5374 27.9994 2.80003 28C3.22268 27.9997 3.63148 27.8525 3.95353 27.5847L3.96253 27.5759L8.78353 23.4423C8.89303 23.3521 9.03188 23.3032 9.17502 23.3043H29.2C29.6774 23.3043 30.1352 23.1188 30.4728 22.7886C30.8104 22.4584 31 22.0105 31 21.5435V2.76087C31 2.29386 30.8104 1.84597 30.4728 1.51575C30.1352 1.18552 29.6774 1 29.2 1ZM29.8 21.5435C29.8 21.6991 29.7368 21.8484 29.6243 21.9585C29.5117 22.0686 29.3591 22.1304 29.2 22.1304H9.17502C8.74694 22.1316 8.33307 22.2808 8.00653 22.5516L3.18253 26.6896C3.09492 26.7605 2.98852 26.8056 2.87571 26.8197C2.76291 26.8337 2.64835 26.8161 2.54539 26.7689C2.44243 26.7217 2.3553 26.6468 2.29415 26.553C2.23301 26.4592 2.20037 26.3504 2.20003 26.2391V2.76087C2.20003 2.6052 2.26325 2.4559 2.37577 2.34583C2.48829 2.23575 2.6409 2.17391 2.80003 2.17391H29.2C29.3591 2.17391 29.5117 2.23575 29.6243 2.34583C29.7368 2.4559 29.8 2.6052 29.8 2.76087V21.5435Z" fill={props.mainColor}/>
            </g>
            <defs>
                <clipPath id="clip0_413_1126">
                    <rect width="32" height="29" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}

export default MessageIcon;