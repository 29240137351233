import OpenBookIcon from "../Icons/OpenBookIcon";
import MenuButton from "../MenuButton/MenuButton";

import {PATHS} from "../../services/Consts";

import StudentHatIcon from "../Icons/StudentHatIcon";
import CalendarIcon from "../Icons/CalendarIcon";
import ProfileIcon from "../Icons/ProfileIcon";
import MessageIcon from "../Icons/MessageIcon";

import style from "./Menu.module.css";
import { useState } from "react";
import InviteIcon from "../Icons/InviteIcon";
import { useContext } from "react";

import {Context} from "../../App";

function Menu(props) {

    const {isMobile} = props

    const context = useContext(Context);

    const [buttons, setButtons] = useState([
        {text: "Мое обучение", path: PATHS.myLearning, icon: (mainColor) => {return <OpenBookIcon mainColor={mainColor}/> }},
        {text: "Все курсы",    path: PATHS.allCourses, icon: (mainColor) => {return <StudentHatIcon mainColor={mainColor}/>}},
        {text: "Расписание",   path: PATHS.timeTable,  icon: (mainColor) => {return <CalendarIcon mainColor={mainColor}/> }},
        {text: "Поддержка",    path: PATHS.support,    icon: (mainColor) => {return <MessageIcon mainColor={mainColor}/> }},
        {text: "Профиль",      path: PATHS.profile,    icon: (mainColor) => {return <ProfileIcon mainColor={mainColor}/> }},
        {text: "Пригласить друга", path: PATHS.invite, icon: (mainColor) => {return <InviteIcon mainColor={mainColor}/> }}
    ]);

    const handleClick = (index) => {
        context.setMenuItem(index);
    }

    return (
            <>
                {
                    buttons.map((button, index)=>{
                        return (
                            <MenuButton
                                key = {index}
                                index = {index}
                                text = {button.text}
                                path = {button.path}
                                icon = {button.icon(context.menuItem === index || isMobile ? "white" : "black")}
                                isPushed = {context.menuItem === index ? true : false}
                                onClick = {()=>{handleClick(index); if (isMobile) props.hideMenu()}}    
                            />
                        )
                    })
                }
            </>

    )
};

export default Menu;