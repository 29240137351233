import style from "./Support.module.css";
import vk from "../img/vk.png"
import { useOutletContext } from "react-router-dom";
import { Context } from "../App";
import { useContext } from "react";
import { useEffect } from "react";


function Support() {
    const context = useContext(Context);
    const handleVKRedirect = () => {
        window.open('https://vk.com/write-226691169', '_blank');
      };
    
      useEffect(()=>{
        context.setTopBarTitle("Поддержка")
      }, [])
      return (
        <div className={style.main}>
        <div className={style.supportСontainer}>
          <button className={style.supportButton} onClick={handleVKRedirect}>
            <img
              src={vk}
              alt="VK logo"
              className={style.VkLogo}
            />
            Перейти в чат поддержки
          </button>
        </div>
        </div>
      );
}

export default Support;