import style from "./NewPassword.module.css";
import logo from "../../img/Logo.png"
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { PATHS } from '../../services/Consts';


function NewPassword({email, setNewPassword}) {

    const [password, setPassword] = useState("")
    const [repeatPassword, setRepeatPassword] = useState("")
    const [isEmptyField, setIsEmptyField] = useState(false)
    const [isRepeatPassword, setIsRepeatPassword] = useState(false)
    const validateMessege = {opacity: isEmptyField ? "1" : "0"}
    const errorMes = "Ошибка, проверьте введеные данные!";
    let userData = {
        email: email,
        new_password: password
    }
    function giveMeCode() {
        fetch('https://extremumcourse.ru/api/v2/account/auth/email/repair', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: userData.email,
                new_password: userData.new_password
            }
            ),
        })
        .then(response => {
            if (response.status === 200) {
              return response.json(); // Возвращаем JSON из ответа
            } else if (response.status === 400) {

              throw new Error("Недопустимое время отправки кода");
            } else {
              throw new Error("Неизвестная ошибка");
            }
          })
          .then(data => {
            const accessToken = data.verification_token; 
            localStorage.setItem('token', accessToken); 
            navigate("../" + PATHS.confirmEmailPswd, { replace: false });

          })
          .catch(error => {
            console.error('Fetch error:', error);
          });
    }
    const navigate = useNavigate()

    function handleSubmit(e) {
        e.preventDefault();
        if (password.length > 0 && repeatPassword.length > 0 && password === repeatPassword ) {
            setNewPassword(userData.new_password)
            setRepeatPassword("")
            setPassword("")
            setIsEmptyField(false)
            setIsRepeatPassword(false)
            giveMeCode()    
        } else {
            setIsEmptyField(true)
        }

    }

    return (
        <div className={style.main}>
            <div className={style.logo}>
                <img src={logo} alt="logo" />
            </div>
            <div className={style.container}>
                <div className={style.titleContainer}>
                    <div сclassName={style.title}>Восстаовление пароля</div>
                </div>
                <div className={style.formContainer}>
                <form className={style.form} onSubmit={handleSubmit}>

                    <div className={style.passwordInputContainer}>
                        <label className={style.passwordLabel}>Введите новый пароль</label>
                        <input className={style.passwordInput} 
                        type="password"
                        onChange={e => setPassword(e.target.value)}
                        value={password}
                        />
                    </div>
                    <div className={style.repeatPasswordInputContainer}>
                        <label className={style.repeatPasswordLabel}>Повторите новый пароль</label>
                        <input className={style.repeatPasswordInput} 
                        type="password"
                        onChange={e => setRepeatPassword(e.target.value)}
                        value={repeatPassword}
                        />
                        
                        <div className={style.validateMessege} style={validateMessege}>{(isEmptyField || isRepeatPassword) ? errorMes : "пасхалка"}</div>
                    </div>
                    
                    <button className={style.btnSave} onClick={handleSubmit}>Сохранить пароль</button>
                </form>
                
            </div>

        </div>
    </div>
    )
    }


export default NewPassword;