import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, Outlet, useOutletContext } from "react-router-dom";
import style from './AllCourses.module.css';
import lock from "../img/lock.png";
import axios from 'axios';
import tick1 from "../img/tick1.png";
import tick2 from "../img/tick2.png";
import { IoMdClose } from "react-icons/io";
import { useNavigate } from 'react-router-dom';
import { PATHS } from '../services/Consts';
import star from "../img/Star.png"
import Chat from "../components/Chat/Chat"
import Mark from "../components/Mark/Mark"
import Loading from "../../src/components/Load/Loading"

import { Context } from '../App';
import { useContext } from 'react';

function AllCourses({getPlanData, getCourseData, setCourseImg }) {
    const [isOpenModalWindow, setIsOpenModalWindow] = useState(false);
    const [selectedCourse, setSelectedCourse] = useState(null);
    const [courses, setCourses] = useState([]);
    const [showIcons, setShowIcons] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [width, setWidth] = useState(window.innerWidth);
    const [images, setImages] = useState([]);
    const [id, setId] = useState([])
    const [isOpen, setIsOpen] = useState(false)
    const [isMarkOpen, setIsMarkOpen] = useState(false)
    const [textArea, setTextArea] = useState(null)
    const [loading, setLoading] = useState(true)

    const navigate = useNavigate();
    const context = useContext(Context);

    function handleCoursePlans(course) {
        getCourseData(course);
        setCourseImg(images[course.courseId])
        navigate(`../${PATHS.plans}`, { replace: false });
        
    }
    function buyCourseDirect(planId) {
        const plan = selectedCourse.plans.find(p => p.planId === planId);
        if (plan) {
            setSelectedPlan(plan);
            getPlanData({
                plan,
                courseTitle: selectedCourse.title,
                courseImage: images[selectedCourse.courseId]
            });
            navigate(`../${PATHS.basket}`, { replace: false });
        }
    }

    const openModal = (course) => {
        setSelectedCourse(course);
        setIsOpenModalWindow(true);
    };

    const closeModal = () => {
        setIsOpenModalWindow(false);
        setSelectedCourse(null);
    };

    const sortPlans = (plans) => {
        const order = { "Стандарт": 1, "Премиум": 2, "Самоучка": 3 };
        return plans.sort((a, b) => order[a.title] - order[b.title]);
    };

    const getPlanClass = (title) => {
        switch (title) {
            case "Стандарт":
                return style.planStandard;
            case "Премиум":
                return style.planPremium;
            case "Самоучка":
                return style.planSelfLearner;
            default:
                return "";
        }
    };

    const getMinimumCost = (plans) => {
        const validCosts = plans
            .filter(plan => plan.costPerBlock !== null)
            .map(plan => plan.costPerBlock);
        return Math.min(...validCosts);
    };
    
    const fetchCourseImages = (courseIds) => {
        const requests = courseIds.map(courseId => {
            return axios.get(`https://extremumcourse.ru/api/my-edu/course/${courseId}/image`, {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem('token')}`
                },
                responseType: 'blob' 
            })
            .then(response => {
                const imageUrl = URL.createObjectURL(response.data);
                return { courseId, imageUrl };
            })
            .catch(error => {
                console.error(`Error fetching image for course ${courseId}:`, error);
                return { courseId, imageUrl: null }; 
            });
        });
        Promise.all(requests)
            .then(results => {
                const imageMap = results.reduce((acc, { courseId, imageUrl }) => {
                    acc[courseId] = imageUrl;
                    return acc;
                }, {});
                setImages(imageMap);
            })
            .catch(error => {
                console.error('Error fetching course images:', error);
            });
    };
    
    
    useEffect(() => {
        context.setTopBarTitle("Все курсы");
        axios.get('https://extremumcourse.ru/api/courses', {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json'
            }
        })
        .then(response => { 
    
            if (Array.isArray(response.data)) {
                const data = response.data.map(course => ({
                    ...course,
                    plans: sortPlans(course.plans)
                }));
     
    
                setCourses(data);
                setLoading(false);
                const courseIds = data.map(course => course.courseId); 
                setId(courseIds);
                fetchCourseImages(courseIds);
                const iconsVisibility = data.map(course => {
                    return course.plans.some(plan => plan.purchased) ? false : true;
                });    
                setShowIcons(iconsVisibility);
            } else {
                console.error('Response data is not an array');
            }
        })
        .catch(error => console.error('Error fetching courses:', error));
    }, []);
    
    
    const getPlanContainerClass = (plans) => {
        if (plans.length === 1) {
            return style.singlePlanContainer;
        } else if (plans.length === 2) {
            return style.twoPlansContainer;
        } else {
            return style.threePlansContainer;
        }
    };

    const toCourse = (course) => {
        navigate(`../${PATHS.myLearning}/${course.courseId}`);
        context.setMenuItem(0);
    }
    if (loading) {
        return (
            <div className={style.laod}><Loading style={{marginTop: "-20vh"}} /></div>
            
        )
    } else {
    if (width > 768) {
    return (
        <div className={style.mainContainer}>
            {courses.map((course, index) => (
                <div onClick={() => showIcons[index] ? openModal(course) : toCourse(course)} key={index} className={style.courseContainer}>
                    <img className={style.courseImg} src={images[course.courseId]} alt="course" />
                    <div className={style.titleContainer}>
                        <div className={style.headerContainer}>
                            <div className={style.courseName}>{course.title}</div>
                            <div className={style.status}>{showIcons[index] ? <img className={style.lock} src={lock} alt="" /> : null}</div>
                        </div>

                        
                            <div className={style.courseDescription}>{course.description}</div>
                        

                        <div className={style.footerContainer}>
                            <div className={style.lessons}>{course.lessons} {course.lessons % 10 === 1 ? "урок" : course.lessons % 10 >= 2 && course.lessons % 10 <= 4 ? "урока" : "уроков"}</div>
                            <div className={style.minimumCost}>От  <span className={style.cost}>{getMinimumCost(course.plans)} ₽</span></div>
                        </div>
                    </div>
                </div>
            ))}


            {isOpenModalWindow && (
                <div className={style.modal} onClick={closeModal}>
                    <div className={style.modalContent} onClick={(e) => e.stopPropagation()}>

                        <div className={style.header}>
                            <div className={style.imgContainer}><img className={style.imgModal} src={images[selectedCourse.courseId]} alt="img" /></div>
                            <div className={style.courseName}>{selectedCourse.title}</div>
                            <div className={style.descriptContainer}>
                                <div className={style.courseDescriptionModal}>{selectedCourse.description}</div>
                                <button onClick={() => {setIsOpen(true)}} className={style.btnQuestion}>Задать вопрос</button>
                            </div>
                        </div>
                        <div className={style.body}>
                            <div className={`${style.planContainer} ${getPlanContainerClass(selectedCourse.plans)}`}>

                            {selectedCourse.plans.map((plan, index) => (
    <div key={index} className={`${style.plan} ${getPlanClass(plan.title)}`}>
        {plan.title === "Самоучка" ? (
            <div 
                className={
                    selectedCourse.plans.length < 3 
                        ? `${style.planInfoConntainerSelf} ${style.flexColumn}` 
                        : style.planInfoConntainerSelf
                }
            >
                <div 
                    className={
                        selectedCourse.plans.length < 3 
                            ? ` ${style.dashedBorder}` 
                            : style.planSelfLearnerHeader
                    }
                >
                    <div className={style.planTitleSelf}>{plan.title}</div>
                    <div 
                        className={
                            selectedCourse.plans.length < 3 
                                ? `${style.planPriceContainerSelf} ${style.flexColumnReverse} ${style.marginTopBottom}` 
                                : style.planPriceContainerSelf
                        }
                    >
                        <span className={style.planPriceSelf}>{plan.costPerBlock} ₽</span>
                        {plan.lastCostPerBlock && (
                            <span className={style.planOldPriceSelf}>{plan.lastCostPerBlock} ₽</span>
                        )}
                    </div>
                </div>
                <div 
                    className={
                        selectedCourse.plans.length < 3 
                            ? `${style.planInfoContainerSelf} ${style.flexColumn}` 
                            : style.planInfoContainerSelf
                    }
                >
                    <div 
                        className={
                            selectedCourse.plans.length < 3 
                                ? `${style.planDescriptionItemSelf} ${style.flexRow} ${style.marginTop}` 
                                : style.planDescriptionItemSelf
                        }
                    >
                        <img src={tick2} alt="" />
                        <div>{plan.descriptionItems[0]}</div>
                    </div>
                    <button 
                        onClick={() => buyCourseDirect(plan.planId)} 
                        className={
                            selectedCourse.plans.length < 3 
                                ? `${style.planButtonSelf} ${style.fullWidth}` 
                                : style.planButtonSelf
                        }
                    >
                        Начать
                    </button>
                </div>
            </div>
        ) : (
                                            <>
                                                <div className={style.planHeader}>
                                                    {plan.title === "Стандарт" ? 
                                                    <div className={style.popularContainer}>
                                                        <div className={style.popular}>Самый популярный</div>
                                                        <img className={style.star} src={star} alt="" />
                                                    </div> : null}
                                                    <br />
                                                    <div className={style.planHeaderContainer}>
                                                        <div className={style.planTitle}>{plan.title}</div>
                                                        <div className={style.freePlaces}>Осталось мест: <span className={style.num}>{plan.freePlaces}</span></div>
                                                    </div>
                                                    <div className={style.planInfoContainer}>
                                                        <div className={style.planPriceContainer}>
                                                            <span className={style.planPrice}>{plan.costPerBlock} ₽<span className={style.titleSale}>/месяц</span></span>
                                                            {plan.lastCostPerBlock && (
                                                                <span className={style.planOldPrice}>{plan.lastCostPerBlock} ₽</span>
                                                            )}
                                                            <span className={style.planPriceCourse}>{plan.cost} ₽<span className={style.titleSale}>/курс</span></span>
                                                            {plan.lastCostPerBlock && (
                                                                <span className={style.planOldPriceCourse}>{plan.lastCost} ₽</span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={style.planDescription}>
                                                    {plan.descriptionItems.map((item, idx) => (
                                                        <div key={idx} className={style.planDescriptionItem}>
                                                            <img src={plan.title === "Стандарт" ? tick1 : tick2} alt="" />
                                                            <div>{item}</div>
                                                        </div>
                                                    ))}
                                                </div>
                                                <button onClick={() => buyCourseDirect(plan.planId)} className={style.planButton}>Начать</button>
                                            </>
                                            
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className={style.close} onClick={closeModal}><IoMdClose /></div>
                </div>
            )}
            <div>
            {isOpen && (
    <div className={style.modal2} onClick={() => setIsOpen(false)}>
        <div className={style.modalContent2} onClick={(e) => e.stopPropagation()}>
                <Chat/>
        </div>
        <div className={style.close2} onClick={() => {setIsOpen(false); setIsMarkOpen(true)}}><IoMdClose /></div>
    </div>
)}

            </div>
            <div>
            {isMarkOpen && (
                <div className={style.modal3} onClick={() => setIsOpen(false)}>
                <div className={style.modalContent3} onClick={(e) => e.stopPropagation()}>
                        <Mark setTextArea={setTextArea}/>
                </div>
                <div className={textArea ? style.close3 : style.close32} onClick={() => {setIsMarkOpen(false)}}><IoMdClose /></div>
            </div>
            )}
            </div>
        </div>
        
    );} else {
            return (
                <div className={style.mainContainer}>
                    {courses.map((course, index) => (
                    <div onClick={() => showIcons[index] ? handleCoursePlans(course) : navigate(`../${PATHS.myLearning}/${course.courseId}`)}  key={index} className={style.courseContainer} >
                       <>
    <div className={style.imageWrapper}>
        <img className={style.courseImg} src={images[course.courseId]} alt="" />
        <div className={style.status}>
            {showIcons[index] ? <img className={style.lock} src={lock} alt="" /> : null}
        </div>
    </div>
</>
                        <div className={style.courseName}>{course.title}</div>
                        <div className={style.lessons}>{course.lessons} {course.lessons % 10 === 1 ? "урок" : course.lessons % 10 >= 2 && course.lessons % 10 <= 4 ? "урока" : "уроков"}</div>
                        <div className={style.courseDescription}>{course.description}</div>
                        <div className={style.minimumCost}>От  <span className={style.cost}>{getMinimumCost(course.plans)} ₽</span></div>
                    </div>
            ))}
                </div>
        )
    }
}
}

export default AllCourses;